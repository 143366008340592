import React from 'react';

import { Container } from '@mui/material';

import backgroundImage from '../assets/images/login_bg.jpeg';

interface Props {
  children?: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => (
  <Container
    maxWidth={false}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100vh',
    }}
  >
    {children ?? <div style={{ height: 496 }} />}
  </Container>
);

export default AuthLayout;
