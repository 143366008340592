import React from 'react';

import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceArrayInputProps,
} from 'react-admin';

const AutocompleteClientArrayInput: React.FC<
  Omit<ReferenceArrayInputProps, 'reference' | 'filterToQuery'>
> = ({ source, filter, ...props }) => (
  <ReferenceArrayInput
    {...props}
    source={source}
    filter={filter}
    reference="Client"
    filterToQuery={(name: string) => ({
      name,
    })}
  >
    <AutocompleteArrayInput optionText="name" filter="name" />
  </ReferenceArrayInput>
);

export default AutocompleteClientArrayInput;
