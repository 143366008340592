import React from 'react';

import { ZonedDateTime } from '@js-joda/core';
import { Typography } from '@mui/material';
import {
  BooleanField,
  BooleanInput,
  Create,
  DateField,
  DateTimeInput,
  Edit,
  Filter,
  FunctionField,
  ReferenceField,
  SaveButton,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';

import { iso8601DateTime } from '../../utils/date';

import AutocompleteClientInput from './components/AutocompleteClientInput';
import InfiniteList from './components/InfiniteList';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const EligibleEmailAddressToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const EligibleEmailAddressFilters: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Last Name" source="lastName" alwaysOn />
    <TextInput label="Employee ID" source="employeeId" alwaysOn />
    <BooleanInput label="Has HPC Eligibility" source="hasHpcEligibility" />
    <AutocompleteClientInput source="clientId" label="Client" alwaysOn />
  </Filter>
);

export const EligibleEmailAddressList: React.FC = () => (
  <InfiniteList filters={<EligibleEmailAddressFilters />}>
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <TextField source="email" sortable={false} />
      <TextField source="lastName" sortable={false} />
      <BooleanField source="isArchived" label="Archived" sortable={false} />
      <BooleanField source="isStaff" label="Staff" sortable={false} />
      <BooleanField
        source="hasHpcEligibility"
        label="Has HPC Eligibility"
        sortable={false}
      />
      <ReferenceField
        source="client.id"
        reference="Client"
        label="Client"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <WriteOnlyEditButton />
      <ShowButton />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

export const EligibleEmailAddressShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Basics">
        <TextField source="id" />
        <TextField source="email" />
        <BooleanField source="isStaff" label="Staff" />
        <BooleanField source="user.registered" label="User Registered" />
        <ReferenceField source="user.id" reference="User" label="User">
          <FunctionField
            render={(record: Record<string, any>) =>
              record &&
              `${record.firstName} ${record.lastName} <${record.email}>`
            }
          />
        </ReferenceField>
        <BooleanField source="isArchived" label="Archived" />
        <DateField source="archivedAt" />
        <ReferenceField source="client.id" reference="Client" label="Client">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="user.id"
          reference="User"
          label="User"
          link="show"
          emptyText="Not registered"
        />
      </Tab>
      <Tab label="High-Performance Coaching">
        <BooleanField
          source="hasHpcEligibility"
          label="Has HPC Eligibility"
          sortable={false}
        />
        <DateField
          source="hpcEligibilityStart"
          label="Start Date (UTC)"
          showTime
        />
        <DateField source="hpcEligibilityEnd" label="End Date (UTC)" showTime />
      </Tab>
      <Tab label="Other">
        <TextField source="invitingRelationship" />
        <ReferenceField
          source="invitingUser.id"
          reference="User"
          label="Inviting User"
        >
          <TextField source="email" />
        </ReferenceField>
        <TextField source="employeeId" />
        <TextField source="lastName" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const StaffInput: React.FC = () => (
  <BooleanInput
    source="isStaff"
    defaultValue
    helperText="If this is unchecked, the user can be archived the next time an eligibility file is uploaded"
  />
);

const HpcInputs: React.FC = (props) => {
  const record = useRecordContext(props);
  const [showDates, setShowDates] = React.useState(record?.hasHpcEligibility);

  return (
    <>
      <BooleanInput
        source="hasHpcEligibility"
        label="Has High-Performance Coaching Eligibility"
        onChange={(e) => setShowDates(e.target.checked)}
      />
      {showDates && (
        <>
          <Typography style={{ marginBottom: 16 }}>
            User-level access to high-performance coaching is based on the
            specified start and end dates.
          </Typography>
          <DateTimeInput
            source="hpcEligibilityStart"
            label="Start Date (UTC)"
            defaultValue={iso8601DateTime(
              ZonedDateTime.now().withFixedOffsetZone(),
            )}
            required
          />
          <DateTimeInput
            source="hpcEligibilityEnd"
            label="End Date (UTC)"
            defaultValue={iso8601DateTime(
              ZonedDateTime.now().plusMonths(3).withFixedOffsetZone(),
            )}
            required
          />
        </>
      )}
    </>
  );
};

export const EligibleEmailAddressEdit: React.FC = () => (
  <Edit mutationMode="pessimistic" redirect={false}>
    <SimpleForm toolbar={<EligibleEmailAddressToolbar />}>
      <TextInput source="id" InputProps={{ disabled: true }} />
      <TextInput source="email" InputProps={{ disabled: true }} />
      <BooleanInput source="isArchived" />
      <StaffInput />
      <HpcInputs />
    </SimpleForm>
  </Edit>
);

export const EligibleEmailAddressCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="email" />
      <AutocompleteClientInput source="client.id" label="Client" />
      <BooleanInput source="isArchived" />
      <StaffInput />
      <HpcInputs />
    </SimpleForm>
  </Create>
);
