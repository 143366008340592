import React from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FieldErrors } from 'react-hook-form';

interface Props {
  name: string;
  errors: FieldErrors;
}

const Errors: React.FC<Props> = ({ name, errors }) => {
  const { palette } = useTheme();
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ messages, message }) => {
        if (message) {
          return (
            <Typography
              variant="subtitle1"
              style={{
                color: palette.error.main,
              }}
            >
              {message}
            </Typography>
          );
        }

        if (!messages) {
          return null;
        }

        return Object.entries(messages).map(([type, msg]) => (
          <Typography
            variant="subtitle1"
            style={{
              color: palette.error.main,
            }}
            key={type}
          >
            {msg}
          </Typography>
        ));
      }}
    />
  );
};

export default Errors;
