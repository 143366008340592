import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import {
  Button,
  Empty,
  ListContextProvider,
  ListView,
  ListViewProps,
  SortProps,
} from 'react-admin';
import styled from 'styled-components';

import useInfiniteListController from './useInfiniteListController';

type PaginationProps = {
  perPage?: number;
};

type InfiniteListProps = ListViewProps & PaginationProps & Partial<SortProps>;

type InfiniteLoaderProps = {
  className?: string;
  isMore?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
};

const InfiniteLoader = styled(
  ({
    className,
    isMore,
    isLoading,
    onLoadMore = noop,
  }: InfiniteLoaderProps) => {
    // Debounce isLoading (i.e., during initial page loads when multiple pages might get loaded to fill the viewport)
    // This keeps the text from switching constantly, but also (and more importantly) makes this easier to test
    const [isLoadingDebounced, setIsLoading] = useState(isLoading);

    const setIsLoadingDebounced = useCallback(debounce(setIsLoading, 100), [
      setIsLoading,
    ]);

    useEffect(() => {
      setIsLoadingDebounced(isLoading);
    }, [isLoading]);

    const loadMore = isMore ? onLoadMore : noop;

    // Observer
    const observerElem = useRef(null);

    const handleObserver = useCallback(
      (entries: any[]) => {
        const [target] = entries;

        if (target.isIntersecting && isMore && !isLoading) {
          loadMore();
        }
      },
      [loadMore, isMore, isLoading],
    );

    useEffect(() => {
      const element = observerElem.current;

      if (!element) {
        return noop;
      }

      const observer = new IntersectionObserver(handleObserver, {
        threshold: 0,
      });

      observer.observe(element);

      return () => observer.unobserve(element);
    }, [observerElem, handleObserver]);

    if (!isMore) {
      return null;
    }

    return (
      <div className={className} ref={observerElem}>
        <Button
          size="large"
          color="inherit"
          data-testid="infinite-loader"
          disabled={isLoadingDebounced || !isMore}
          // Allow clicks as a backup
          onClick={() => loadMore()}
          label={isLoadingDebounced ? 'Loading...' : 'Load More'}
        />
      </div>
    );
  },
)`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const InfiniteList: FC<InfiniteListProps> = (props) => {
  const { perPage, sort } = props;
  const listContext = useInfiniteListController({
    perPage,
    sort,
  });
  const { hasNextPage, isFetching, isLoading, fetchNextPage } = listContext;

  return (
    <ListContextProvider value={listContext}>
      <ListView
        {...props}
        empty={
          isLoading ? (
            <Box display="flex" justifyContent="center" py={10}>
              <CircularProgress />
            </Box>
          ) : (
            <Empty />
          )
        }
        pagination={
          <InfiniteLoader
            isMore={!!hasNextPage}
            isLoading={isFetching || isLoading}
            onLoadMore={fetchNextPage}
          />
        }
      />
    </ListContextProvider>
  );
};

export default InfiniteList;
