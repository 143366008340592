import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const ignoredSamplingUrls = ['localhost', 'mixpanel'];

if (!Sentry.isInitialized()) {
  Sentry.init({
    release: process.env.GATSBY_COMMIT_ID,
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_ENV_LEVEL || 'dev',
    tracesSampler: (samplingContext) => {
      const traceLocation = samplingContext.location?.toString();
      if (
        ignoredSamplingUrls.some((ignoredSamplingUrl) =>
          traceLocation?.includes(ignoredSamplingUrl),
        )
      ) {
        return false;
      }
      return samplingContext.attributes?.['sentry.sample_rate'] ?? true;
    },
    integrations: [
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.GATSBY_ENV_LEVEL === 'prod' ? 0.5 : 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
