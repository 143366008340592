import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { BooleanField, ShowButton } from 'react-admin';

import { CoachDetailsFragment, CoachType } from '../../../graphql/types';
import PaginationWaypoint from '../../reusable/PaginationWaypoint';

interface ListCoachesProps {
  loadMore: () => void;
  coaches?: CoachDetailsFragment[];
  hasNextPage?: boolean;
}
export const ListCoaches: React.FC<ListCoachesProps> = ({
  coaches,
  loadMore,
  hasNextPage,
}) => {
  const getDisplayType = (type?: CoachType | null) => {
    switch (type) {
      case CoachType.Dietitian:
        return 'Nutrition';
      case CoachType.PerformCoach:
        return 'Perform';
      default:
        return 'Coach';
    }
  };

  if (!coaches) {
    return null;
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Style</TableCell>
              <TableCell>Locations</TableCell>
              <TableCell>Countries</TableCell>
              <TableCell>Assignable</TableCell>
              <TableCell>Member Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coaches.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                  No coaches found
                </TableCell>
              </TableRow>
            )}
            {coaches?.map((coach) => (
              <TableRow key={coach.id}>
                <TableCell>{coach.firstName}</TableCell>
                <TableCell>{coach.lastName}</TableCell>
                <TableCell>{coach.email}</TableCell>
                <TableCell>{getDisplayType(coach.coachData?.type)}</TableCell>
                <TableCell>{coach.coachData?.coachingStyle}</TableCell>
                <TableCell>
                  {coach.coachData?.locations?.nodes
                    .map((location) => location.name)
                    .join(', ')}
                </TableCell>
                <TableCell>
                  {coach.coachData?.supportedCountries
                    ?.map((country) => country.name)
                    .join(', ')}
                </TableCell>
                <TableCell>
                  {coach.coachData && (
                    <BooleanField
                      record={coach.coachData}
                      source="availableForMemberAssignment"
                    />
                  )}
                </TableCell>
                <TableCell>{coach.coachData?.coachUsers.count ?? 0}</TableCell>
                <TableCell>
                  <ShowButton record={coach} resource="User" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasNextPage && <div style={{ textAlign: 'center' }}>Loading...</div>}
      {hasNextPage && <PaginationWaypoint callback={loadMore} />}
    </>
  );
};
