import React from 'react';

import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useAuth } from '@teamexos/fit-shared';
import { Navigate } from 'react-router-dom';

import { useSelfQuery } from '../graphql/types';
import useLogout from '../hooks/useLogout';

import AuthLayout from './AuthLayout';

const PrivateRoute: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const logout = useLogout();
  const { isLoggedIn, isLoading: authIsLoading } = useAuth();

  const { data, loading, error } = useSelfQuery({
    fetchPolicy: 'cache-first',
    skip: !isLoggedIn || authIsLoading,
  });

  if ((!authIsLoading && !isLoggedIn) || error) {
    return <Navigate to="/app/login" replace />;
  }

  if (loading || authIsLoading || !isLoggedIn) {
    return (
      <Box display="flex" justifyContent="center" py={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.self?.isAdmin) {
    return (
      <AuthLayout>
        <Typography variant="h1">Not Authorized</Typography>
        <Button variant="contained" color="primary" onClick={logout}>
          Logout
        </Button>
      </AuthLayout>
    );
  }

  return children;
};

export default PrivateRoute;
