import React from 'react';

import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Title } from 'react-admin';

import { CustomTabPanel } from '../components/CustomTabPanel';

import { CreateCoach } from './CreateCoach';

export const TestData = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box marginTop={2}>
      <Title title="Test Data" />
      <Typography variant="h3">Test Data</Typography>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label="Create Coach" />
      </Tabs>
      <CustomTabPanel index={0} value={tabValue}>
        <Paper sx={{ p: 3 }}>
          <CreateCoach />
        </Paper>
      </CustomTabPanel>
    </Box>
  );
};
