import React, { useEffect, useRef } from 'react';

import { DateTimeFormatter, ZoneId, ZonedDateTime } from '@js-joda/core';
import type {
  AvailabilityViewerControl,
  AvailabilityViewerOptions,
} from 'cronofy-elements';
import noop from 'lodash/noop';
import styled from 'styled-components';

import { CalendarBufferFragment } from '../../../graphql/types';

interface Props {
  elementToken: string;
  config?: AvailabilityViewerOptions['config'];
  coachSub: string;
  availableDays?: number[];
  calendarBuffer?: CalendarBufferFragment;
  dailySessionLimit?: number | null;
  coachTimezone?: string | null;
}

const defaultElementConfig: AvailabilityViewerOptions['config'] = {
  interval: 30,
  mode: 'no_confirm',
  start_time: '08:00',
  end_time: '18:00',
  allow_expansion: true,
};

const StyledAvailabilityViewer = styled.div`
  font-family: reader;
`;

const cronofy8601 = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ssZ");

export const toCronofyDate = (zdt: ZonedDateTime) =>
  zdt.withZoneSameInstant(ZoneId.UTC).format(cronofy8601);

const AvailabilityViewerWrapper: React.FC<Props> = ({
  config = {},
  elementToken,
  coachSub,
  calendarBuffer,
  dailySessionLimit,
  coachTimezone,
}) => {
  const elementRef = useRef<AvailabilityViewerControl>();
  const options: AvailabilityViewerOptions = React.useMemo(
    () => ({
      element_token: elementToken,
      target_id: 'cronofy-availability-viewer',
      config: { ...defaultElementConfig, ...config },
      callback: noop,
      availability_query: {
        participants: [
          {
            required: 'all',
            members: [
              {
                sub: coachSub,
                managed_availability: true,
                ...(dailySessionLimit && {
                  availability_constraints: [
                    {
                      limit: dailySessionLimit,
                      period: 'day',
                      tzid: coachTimezone ?? 'Etc/GMT-0',
                      tags: {
                        private: [
                          {
                            value: 'exos_session',
                          },
                        ],
                      },
                    },
                  ],
                }),
              },
            ],
          },
        ],
        response_format: 'overlapping_slots',
        query_periods: [
          {
            start: toCronofyDate(ZonedDateTime.now(ZoneId.UTC)),
            end: toCronofyDate(ZonedDateTime.now(ZoneId.UTC).plusDays(45)),
          },
        ],
        required_duration: { minutes: 30 },
        start_interval: { minutes: 30 },
        buffer: {
          before: { minutes: calendarBuffer?.beforeInMinutes ?? 0 },
          after: { minutes: calendarBuffer?.afterInMinutes ?? 0 },
        },
      },
    }),
    [config, elementToken],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      const { AvailabilityViewer } = require('cronofy-elements');
      elementRef.current = AvailabilityViewer(options);
    }
  }, [options]);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.update(options);
    }
  }, [options]);

  return <StyledAvailabilityViewer id="cronofy-availability-viewer" />;
};

export default AvailabilityViewerWrapper;
