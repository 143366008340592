import { Prefix, useGenerateSignedUrlMutation } from '../graphql/types';

export const imageErrors = {
  GENERIC_ERROR: 'Failed to upload image. Please try again later',
  TOO_BIG: 'This file is too large to send. We support up to 5MB.',
  UNSUPPORTED_FILE_TYPE: 'This file is not supported. Try a jpg or png.',
};

export function useUploadFile() {
  const [generateSignedUrl] = useGenerateSignedUrlMutation();

  function isFileTooLarge(file: File) {
    return file.size / 1000000 > 5;
  }

  function getImageData(
    file: File,
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      const img = document.createElement('img');
      const objUrl = URL.createObjectURL(file);
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
        URL.revokeObjectURL(objUrl);
        img.remove();
      };
      img.src = objUrl;
    });
  }

  function validateImage(file: File) {
    const response = {
      isValid: false,
      message: '',
    };
    // check type
    if (!file.type.match(/jpeg|png/gi)) {
      response.message = imageErrors.UNSUPPORTED_FILE_TYPE;
      return response;
    }
    // check size
    if (isFileTooLarge(file)) {
      response.message = imageErrors.TOO_BIG;
      return response;
    }
    response.isValid = true;
    return response;
  }

  async function uploadFile(
    file: File,
    prefix: Prefix = Prefix.Uploads,
  ): Promise<{ url: string; key: string }> {
    const { width, height } = await getImageData(file);
    return new Promise((resolve, reject) => {
      try {
        if (!file) {
          reject(new Error('You must have a file to upload'));
          return;
        }
        const { isValid, message } = validateImage(file);
        if (!isValid) {
          reject(new Error(message));
          return;
        }

        // need to generate URL where we will upload file to
        generateSignedUrl({
          variables: {
            input: {
              prefix,
              contentType: file.type,
            },
          },
        })
          .then(async (result) => {
            if (!result?.data) {
              reject(new Error(imageErrors.GENERIC_ERROR));
              return;
            }

            const { signedUrl, photo, key } = result.data.generateSignedUrl;

            // finally upload image
            await fetch(signedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': file.type,
              },
              body: file,
            }).catch((error) => reject(error));

            // Build URL for image that was just uploaded
            const url = `${photo.basePath}/${
              photo.id
            }/${width}x${height}.${file.type.replace('image/', '')}`;

            resolve({ url, key });
          })
          .catch((error) => {
            if (error.message.includes('Invalid image type')) {
              reject(new Error(imageErrors.UNSUPPORTED_FILE_TYPE));
            }
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  return { uploadFile, isFileTooLarge, getImageData, validateImage };
}
