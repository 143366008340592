import React from 'react';

import { Modal as ModalMUI } from '@mui/material';
import Box from '@mui/material/Box';
import styled from 'styled-components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px 8px',
  borderRadius: 4,
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  withPadding?: boolean;
  children?: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  withPadding = true,
  children,
}) => (
  <ModalMUI
    open={isOpen}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={{ ...style, padding: withPadding ? style.padding : '' }}>
      {children}
    </Box>
  </ModalMUI>
);

export const ModalContent = styled.div({
  height: 150,
  width: 552,
  display: 'flex',
  margin: '0 auto',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const ModalTitle = styled.h1({
  fontFamily: 'poppins',
  fontSize: 20,
  fontWeight: 500,
  margin: 0,
});

export const ModalDescription = styled.p({
  fontFamily: 'Inter',
  fontSize: 14,
  fontWeight: 300,
  marginBottom: 16,
});

export default Modal;
