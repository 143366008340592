import styled from 'styled-components';

import { BORDER_GRAY } from '../../../theme';

export const Divider = styled.div<{
  $marginTop?: number;
  $marginBottom?: number;
}>`
  border-top: 1px solid ${BORDER_GRAY};
  width: 100%;
  margin: 0;
  margin-top: ${({ $marginTop }) => $marginTop ?? 8}px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 8}px;
`;
