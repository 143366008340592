import React from 'react';

import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceArrayInputProps,
} from 'react-admin';

const AutocompleteUserArrayInput: React.FC<
  Omit<ReferenceArrayInputProps, 'reference' | 'filterToQuery'>
> = ({ source, filter, inputProps, ...props }) => (
  <ReferenceArrayInput
    {...props}
    source={source}
    filter={filter}
    reference="User"
    filterToQuery={(email: string) => ({
      email,
    })}
  >
    <AutocompleteArrayInput
      optionText="email"
      filter="email"
      clearOnBlur={false}
      fullWidth
      {...inputProps}
    />
  </ReferenceArrayInput>
);

export default AutocompleteUserArrayInput;
