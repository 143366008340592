import React from 'react';

import * as Sentry from '@sentry/react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Admin from '../components/Admin';
import PrivateRoute from '../components/PrivateRoute';
import SignIn from '../components/SignIn';

const routesConfig = createRoutesFromElements(
  <Route path="/">
    {/* Redirect from / to /app */}
    <Route index element={<Navigate to="/app" replace />} />
    <Route path="app">
      <Route path="login" element={<SignIn />} />
      <Route
        index
        element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        }
      />
    </Route>

    {/* Redirect from /anything to /app - this is required in addition to the redirect above */}
    <Route path="*" element={<Navigate to="/app" replace />} />
  </Route>,
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const browserRouter = sentryCreateBrowserRouter(routesConfig, {
  future: {
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
    v7_normalizeFormMethod: true,
    v7_fetcherPersist: true,
    v7_relativeSplatPath: true,
  },
});
export const BrowserRouter = () => (
  <RouterProvider
    router={browserRouter}
    future={{
      v7_startTransition: true,
    }}
  />
);
