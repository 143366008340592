import { useState } from 'react';

import {
  AdminListCoachUsersQuery,
  useAdminListCoachUsersQuery,
} from '../graphql/types';

interface UseCoachMembersQuery {
  coachId: string;
}
interface UserCoachMembersQueryResponse {
  data?: AdminListCoachUsersQuery;
  loading: boolean;
  isFetchingMore: boolean;
  hasNextPage?: boolean;
  error: any;
  fetchMore: () => Promise<void>;
  refetch: () => Promise<any>;
}
export function useCoachMembersQuery({
  coachId,
}: UseCoachMembersQuery): UserCoachMembersQueryResponse {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { data, loading, error, fetchMore, refetch } =
    useAdminListCoachUsersQuery({
      fetchPolicy: 'cache-first',
      variables: {
        id: coachId,
        input: {
          first: 25,
        },
      },
    });
  const hasNextPage = data?.user?.coachData?.coachUsers.pageInfo.hasNextPage;

  const loadMore = async () => {
    if (hasNextPage) {
      setIsFetchingMore(true);
      await fetchMore({
        variables: {
          id: coachId,
          input: {
            after: data?.user?.coachData?.coachUsers.pageInfo.endCursor,
            first: 25,
          },
        },
      });
      setIsFetchingMore(false);
    }
  };

  return {
    data,
    loading,
    error,
    isFetchingMore,
    hasNextPage,
    fetchMore: loadMore,
    refetch,
  };
}
