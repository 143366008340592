import React, { useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import { Box, TextField, Typography } from '@mui/material';
import { Button, Loading, Title } from 'react-admin';
import { Form, useField } from 'react-final-form';
import styled from 'styled-components';

import {
  App,
  Os,
  useGetSystemPreferencesQuery,
  useSetMinBuildNumberMutation,
} from '../../graphql/types';
import useCanWrite from '../../hooks/useCanWrite';

type MinBuildNumberFieldProps = {
  initialValue: number;
  label: string;
  name: string;
};

const AppBuildSection = styled.section({
  marginBottom: 16,
  backgroundColor: '#fff',
  borderRadius: 4,
  padding: 16,
});

const MinBuildNumberFieldContainer = styled.div({
  marginBottom: 4,
  marginTop: 8,
  width: 256,
});

const MinBuildNumberField: React.FC<MinBuildNumberFieldProps> = ({
  initialValue,
  label,
  name,
}) => {
  const {
    input: { onChange, value },
  } = useField(name, { initialValue });

  return (
    <MinBuildNumberFieldContainer>
      <TextField
        name={name}
        type="number"
        label={label}
        onChange={onChange}
        size="small"
        value={value}
      />
    </MinBuildNumberFieldContainer>
  );
};

const MinBuildNumberForm = () => {
  const canWrite = useCanWrite();
  const [refetching, setRefetching] = useState(false);
  const { loading, data, refetch } = useGetSystemPreferencesQuery();
  const [setMinBuildNumberMutation] = useSetMinBuildNumberMutation();

  if (refetching || loading || !data?.systemPreferences) {
    return <Loading />;
  }

  type Keys = Exclude<keyof typeof data.systemPreferences, '__typename'>;

  const {
    minFitAndroidBuild,
    minFitIosBuild,
    minPerformAndroidBuild,
    minPerformIosBuild,
  } = data?.systemPreferences ?? {};

  const getMutationVars = (key: Keys) => {
    switch (key) {
      case 'minFitAndroidBuild':
        return {
          app: App.Heart,
          os: Os.Android,
        };
      case 'minFitIosBuild':
        return {
          app: App.Heart,
          os: Os.Ios,
        };
      case 'minPerformAndroidBuild':
        return {
          app: App.Prince,
          os: Os.Android,
        };
      case 'minPerformIosBuild':
        return {
          app: App.Prince,
          os: Os.Ios,
        };
      default:
        throw new Error(`Unexpected key ${key}`);
    }
  };

  const submit = async (values: Record<Keys, number | string>) => {
    const mutationPromises: Promise<any>[] = [];

    Object.entries(values).forEach(([key, value]) => {
      // changed values are strings
      if (typeof value === 'number') {
        return;
      }

      const vars = getMutationVars(key as Keys);

      const promise = setMinBuildNumberMutation({
        variables: {
          input: {
            ...vars,
            buildNumber: Number(value),
          },
        },
      });

      mutationPromises.push(promise);
    });

    setRefetching(true);
    await Promise.all(mutationPromises);
    await refetch();
    setRefetching(false);
  };

  return (
    <div>
      <Title title="System Preferences" />
      <Box marginTop={2}>
        <Form
          initialValues={{
            minFitAndroidBuild,
            minFitIosBuild,
            minPerformAndroidBuild,
            minPerformIosBuild,
          }}
          onSubmit={submit}
        >
          {({ handleSubmit }) => (
            <>
              <Typography variant="h3">Minimum Build Numbers</Typography>
              <Typography variant="subtitle1">
                Users with apps below this number will be locked out of the app
                until they update
              </Typography>
              <AppBuildSection>
                <Typography variant="h4">Fit</Typography>
                <MinBuildNumberField
                  initialValue={minFitAndroidBuild}
                  label="Fit Android"
                  name="minFitAndroidBuild"
                />
                <MinBuildNumberField
                  initialValue={minFitIosBuild}
                  label="Fit iOS"
                  name="minFitIosBuild"
                />
              </AppBuildSection>

              <AppBuildSection>
                <Typography variant="h4">Perform</Typography>
                <MinBuildNumberField
                  initialValue={minPerformAndroidBuild}
                  label="Perform Android"
                  name="minPerformAndroidBuild"
                />
                <MinBuildNumberField
                  initialValue={minPerformIosBuild}
                  label="Perform iOS"
                  name="minPerformIosBuild"
                />
              </AppBuildSection>
              {/* GOTCHA: this button doesn't work for readOnly admins */}
              {canWrite && (
                <Button
                  color="primary"
                  style={{
                    marginTop: 20,
                    width: 256,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                  label="SAVE"
                  endIcon={<SaveIcon />}
                />
              )}
            </>
          )}
        </Form>
      </Box>
    </div>
  );
};

export const SystemPreferences = () => <MinBuildNumberForm />;
