import * as React from 'react';

import { BooleanInput, FunctionField, TextInput } from 'react-admin';

import { CoachingStyle } from '../../../graphql/types';

export const UserTabStyle = { fontSize: 14 };

export const getCoachingStyleLabel = (
  value: CoachingStyle | string | null | undefined,
) => (value === CoachingStyle.Hybrid ? 'Onsite-Hybrid' : value);

export const UserTitle: React.FC = () => (
  <FunctionField
    render={(record?: Record<string, any>) =>
      `${record?.firstName} ${record?.lastName} <${record?.email}>`
    }
  />
);

export const UserFilters = [
  <TextInput label="Email" source="email" alwaysOn />,
  <BooleanInput label="Admin" source="isAdmin" />,
  <BooleanInput label="Coach" source="isCoach" />,
  <BooleanInput label="Test user" source="isTestUser" />,
];
