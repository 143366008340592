import * as React from 'react';

import { Datagrid, DatagridProps } from 'react-admin';

import useCanWrite from '../../../hooks/useCanWrite';

/**
 * Thin wrapper around the standard ReactAdmin DataGrid.
 * Hides bulkActionButtons if the user may not write.
 */
export const WriteOnlyDatagrid: React.FC<DatagridProps> = (props) => {
  const canWrite = useCanWrite();
  const { bulkActionButtons, ...rest } = props;

  /**
   * GOTCHA: There are places in Admin where we pass bulkActionButtons=false
   * because we don't want ANYONE to see the buttons. That is why the default
   * below is props.bulkActionItems. If the user !canWrite we want to hide
   * the buttons even if bulkActionItems wasn't passed.
   */
  const hideActionButtons = !canWrite ? false : bulkActionButtons;

  return (
    <Datagrid
      {...rest}
      bulkActionButtons={hideActionButtons}
      rowClick={false}
    />
  );
};
