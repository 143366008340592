import React, { useEffect, useState } from 'react';

import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceArrayInputProps,
} from 'react-admin';

import {
  AdminCoachDataDetailsFragment,
  CoachingStyle,
  useAdminListLocationsLazyQuery,
} from '../../../graphql/types';

interface ClientAndLocationInputProps {
  record: AdminCoachDataDetailsFragment | undefined;
}

const ClientAndLocationInput: React.FC<
  Omit<ReferenceArrayInputProps, 'reference' | 'filterToQuery'> &
    ClientAndLocationInputProps
> = ({ source, filter, record, ...props }) => {
  const clientIds =
    record?.coachData?.clients?.nodes?.map(({ id }) => id) || [];
  const [selectedClientIds, setSelectedClientIds] = useState(clientIds);

  const [fetchLocations, { data: clientLocationsData }] =
    useAdminListLocationsLazyQuery();

  useEffect(() => {
    fetchLocations({
      variables: {
        input: {
          first: 100,
        },
        filter: { clientIds: selectedClientIds, studioIsArchived: false },
      },
    });
  }, [fetchLocations, selectedClientIds]);

  return (
    <>
      <ReferenceArrayInput
        {...props}
        source={source}
        filter={filter}
        reference="Client"
        filterToQuery={(name: string) => ({
          name,
        })}
      >
        <AutocompleteArrayInput
          onChange={setSelectedClientIds}
          label="Clients"
          optionText="name"
          filter="name"
          sx={{
            minWidth: '300px',
          }}
        />
      </ReferenceArrayInput>
      <AutocompleteArrayInput
        label="Locations"
        source="coachData.locations.ids"
        choices={clientLocationsData?.data?.nodes?.map(({ name, id }) => ({
          name: name || '',
          id,
        }))}
        disabled={selectedClientIds.length === 0}
        validate={[
          (selectedLocations, allValues) => {
            const isHybridCoach =
              allValues.coachData.coachingStyle === CoachingStyle.Hybrid;
            const hasNonVirtualLocation = selectedLocations?.some(
              (locationId: string) =>
                !clientLocationsData?.data?.nodes?.find(
                  (location) => location.id === locationId,
                )?.studio?.isVirtual,
            );

            if (isHybridCoach && !hasNonVirtualLocation) {
              return 'An onsite coach must have at least 1 non-virtual location.';
            }
            return undefined;
          },
        ]}
        style={{ marginBottom: 13 }}
        sx={{
          minWidth: '300px',
        }}
      />
    </>
  );
};

export default ClientAndLocationInput;
