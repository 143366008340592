import * as React from 'react';

import { SaveButton, Toolbar } from 'react-admin';

const EditToolbar: React.FC = ({ ...props }) => (
  <Toolbar {...props}>
    <SaveButton id="save-button" />
  </Toolbar>
);

export default EditToolbar;
