import React from 'react';

import {
  AutocompleteInput,
  AutocompleteInputProps,
  ReferenceInput,
  ReferenceInputProps,
} from 'react-admin';

const AutocompleteUserInput: React.FC<
  Omit<ReferenceInputProps, 'reference' | 'filterToQuery'> & {
    inputProps?: AutocompleteInputProps;
  }
> = ({ source, label, filter, inputProps, ...props }) => (
  <ReferenceInput
    {...props}
    source={source}
    reference="User"
    filter={filter}
    filterToQuery={(email: string) => ({
      email,
    })}
  >
    <AutocompleteInput
      fullWidth
      {...inputProps}
      optionText="email"
      filter="email"
      label={label}
    />
  </ReferenceInput>
);

export default AutocompleteUserInput;
