import { useState } from 'react';

import { useUserAuditLogsQuery as useLogsQuery } from '../graphql/types';

const FIRST = 25;

const useUserAuditLogsQuery = (userId: string | undefined) => {
  // "loading" will be true whenever the query hook is executed
  // but we want a loading state that is specific to when
  // `fetchMore` is executed, and this doesn't seem
  // to be something apollo client provides
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useLogsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: userId!, // see skip
      input: {
        first: FIRST,
      },
    },
    skip: !userId,
  });

  const logs = data?.user?.auditLogs.edges?.map((edge) => edge.node);
  const logCount = data?.user?.auditLogs.count;

  const hasNextPage = data?.user?.auditLogs.pageInfo.hasNextPage;

  const loadMore = async () => {
    if (!hasNextPage) {
      return;
    }

    const endCursor = data?.user?.auditLogs.pageInfo.endCursor;

    setIsFetchingMore(true);

    await fetchMore({
      variables: {
        id: userId,
        input: { first: FIRST, after: endCursor },
      },
    });

    setIsFetchingMore(false);
  };

  return {
    data,
    loadMore,
    logCount,
    logs,
    fetch,
    refetch,
    error,
    loading,
    hasNextPage,
    isFetchingMore,
  };
};

export default useUserAuditLogsQuery;
