import { useState } from 'react';

import { useLocationCoachesQuery as useCoachLocationCoachesQuery } from '../graphql/types';

const FIRST = 25;

const useLocationCoachesQuery = (locationId: string | undefined) => {
  // "loading" will be true whenever the query hook is executed
  // but we want a loading state that is specific to when
  // `fetchMore` is executed, and this doesn't seem
  // to be something apollo client provides
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, error, fetchMore, refetch } =
    useCoachLocationCoachesQuery({
      fetchPolicy: 'cache-first',
      variables: {
        id: locationId!,
        input: {
          first: FIRST,
        },
      },
      skip: !locationId,
    });

  const coaches = data?.coachLocation?.coaches.edges.map((edge) => edge.node);
  const coachCount = data?.coachLocation?.coaches?.count;

  const hasNextPage = data?.coachLocation?.coaches?.pageInfo?.hasNextPage;

  const loadMore = async () => {
    if (!hasNextPage && !isFetchingMore) {
      return;
    }

    const endCursor = data?.coachLocation?.coaches?.pageInfo?.endCursor;

    setIsFetchingMore(true);

    await fetchMore({
      variables: {
        id: locationId,
        input: { first: FIRST, after: endCursor },
      },
    });

    setIsFetchingMore(false);
  };

  return {
    data,
    loadMore,
    coachCount,
    coaches,
    fetch,
    refetch,
    error,
    loading,
    hasNextPage,
    isFetchingMore,
  };
};

export default useLocationCoachesQuery;
