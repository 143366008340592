import isNil from 'lodash/isNil';

// https://stackoverflow.com/a/46700791
export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return !isNil(value);
}

export function notUndefined<TValue>(
  value: TValue | null | undefined,
): value is TValue | null {
  return !(value === undefined);
}
