import * as React from 'react';

import { EditButton, EditButtonProps, RaRecord } from 'react-admin';

import useCanWrite from '../../../hooks/useCanWrite';

/**
 * Thin wrapper around the standard ReactAdmin EditButton.
 * Hides the button if the user may not write.
 */
export const WriteOnlyEditButton = <RecordType extends RaRecord = any>(
  props: EditButtonProps<RecordType>,
) => {
  const canWrite = useCanWrite();
  return canWrite && <EditButton {...props} />;
};
