import * as React from 'react';

import {
  AccessTime,
  Check,
  Close,
  FileCopyOutlined,
} from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  List as MUIList,
  Typography,
} from '@mui/material';
import Papa from 'papaparse';
import {
  BooleanField,
  CreateButton,
  FilterButton,
  FunctionField,
  Button as ReactAdminButton,
  ShowButton,
  TextField,
  TopToolbar,
} from 'react-admin';
import { useDropzone } from 'react-dropzone';

import {
  AdminUserBasicsFragment,
  ClientCalendarPlatform,
  ClientVideoPlatform,
  useCreateUsersMutation,
} from '../../../graphql/types';
import useCanWrite from '../../../hooks/useCanWrite';
import { AQUAMARINE, BORDER_GRAY, STATUS_ERROR } from '../../../theme';
import ClipboardField from '../components/ClipboardField';
import InfiniteList from '../components/InfiniteList';
import { MultiTimeAgoField } from '../components/TimeAgoField';
import { WriteOnlyDatagrid } from '../components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from '../components/WriteOnlyEditButton';

import { UserFilters } from './util';

const UserActions: React.FC<{ openModal: () => void }> = ({ openModal }) => {
  const canWrite = useCanWrite();

  return (
    <TopToolbar>
      <FilterButton />
      {canWrite && (
        <>
          <ReactAdminButton onClick={openModal} label="Upload">
            <Add />
          </ReactAdminButton>
          <CreateButton />
        </>
      )}
    </TopToolbar>
  );
};

const UserUploadModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const [error, setError] = React.useState<{ message: string }>();
  const [file, setFile] = React.useState<File>();
  const [parsedFile, setParsedFile] = React.useState<any>();
  const clear = () => {
    setError(undefined);
    setParsedFile(undefined);
    setFile(undefined);
  };
  const closeAndClear = () => {
    onClose();
    // 1 second transition, prevents flickering on close
    setTimeout(() => clear(), 1000);
  };
  const [createUsersMutation, { loading: createLoading }] =
    useCreateUsersMutation({
      onError: ({ message }) => {
        setError({ message });
      },
      onCompleted: () => closeAndClear(),
      variables: {
        input: {
          users: parsedFile,
        },
      },
    });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: {
      'text/html': ['.csv'],
    },
    onDrop: (files) => {
      setError(undefined);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        error: ({ message }) => {
          setError({ message });
        },
        complete: (results, f) => {
          if (results.data.length > 100) {
            setError({ message: "Don't upload a csv with more than 100 rows" });
            return;
          }

          type CSVRow = {
            email: string;
            firstName: string;
            lastName: string;
            app: string;
          };

          if ((results.data as CSVRow[]).some((e) => !e.email)) {
            setError({ message: 'All entries must have an email.' });
            return;
          }

          setFile(f);
          setParsedFile(
            (results.data as CSVRow[]).map((user) => ({
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              app: user.app,
            })),
          );
        },
      });
    },
  });

  const handleCloseClick = () => closeAndClear();

  const handleCancelFileClick = () => clear();

  const handleUploadClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    createUsersMutation();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box width={500} padding={4} paddingRight={6} paddingTop={6}>
        <Box
          onClick={handleCloseClick}
          position="absolute"
          top={15}
          right={20}
          style={{ cursor: 'pointer' }}
        >
          <Close style={{ fill: 'black', opacity: '0.5' }} />
        </Box>
        <Box
          style={{
            cursor: 'pointer',
            backgroundColor: isDragActive ? AQUAMARINE : BORDER_GRAY,
          }}
          padding={5}
          marginBottom={2}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Typography variant="body1">
            Drop your CSV file to upload, or click to select it.
          </Typography>
        </Box>
        {file && (
          <MUIList>
            <ListItem key={file.name} style={{ paddingLeft: 0 }}>
              <ListItemAvatar>
                <Avatar>
                  <FileCopyOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction
                style={{ cursor: 'pointer' }}
                onClick={handleCancelFileClick}
              >
                <Close style={{ width: '18px' }} />
              </ListItemSecondaryAction>
            </ListItem>
          </MUIList>
        )}
        <Box marginTop={2} display="flex" flexDirection="column">
          {error && (
            <Box marginBottom={2} textAlign="center">
              <Typography variant="body1" style={{ color: STATUS_ERROR }}>
                {error.message}
              </Typography>
            </Box>
          )}
          <ReactAdminButton
            disabled={!parsedFile || createLoading || !!error}
            style={{ margin: '0 auto' }}
            variant="contained"
            color="secondary"
            onClick={handleUploadClick}
            size="large"
            label="Upload"
          >
            {parsedFile && createLoading ? (
              <CircularProgress size={18} style={{ marginRight: 4 }} />
            ) : (
              <Add />
            )}
          </ReactAdminButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const computeAdminField = ({
  isAdmin,
  isReadOnlyAdmin,
}: AdminUserBasicsFragment) => {
  if (isAdmin && isReadOnlyAdmin) {
    return 'Read-Only';
  } else if (isAdmin) {
    return <Check />;
  } else {
    return <Close />;
  }
};

const getConnectionStatus = (
  user: AdminUserBasicsFragment | null | undefined,
) => {
  const {
    calendarConnected,
    externalCalendarConnected,
    videoPlatformConnected,
  } = user?.platforms ?? {};

  const { calendarPlatform, videoPlatform } =
    user?.platforms?.clients.nodes?.[0] ?? {};

  const isCalendarConnected = !!calendarConnected;
  const isExternalCalendarEnabled =
    !!calendarPlatform && calendarPlatform !== ClientCalendarPlatform.ExosOnly;
  const isExternalCalendarConnected = !!externalCalendarConnected;
  const isVideoConnected =
    videoPlatform === ClientVideoPlatform.Integrated
      ? calendarConnected
      : !!videoPlatformConnected;

  const isConnected =
    isCalendarConnected &&
    isVideoConnected &&
    (isExternalCalendarEnabled ? isExternalCalendarConnected : true);

  const isInProgress =
    !isConnected &&
    (isCalendarConnected || isVideoConnected || isExternalCalendarConnected);

  return {
    isConnected,
    isInProgress,
    isVideoConnected,
    isCalendarConnected,
    isExternalCalendarEnabled,
    isExternalCalendarConnected,
  };
};

const computeConnectedField = (record?: Record<string, any>) => {
  const { isConnected, isInProgress } = getConnectionStatus(
    record as AdminUserBasicsFragment,
  );

  if (isInProgress) {
    return <AccessTime />;
  }

  return isConnected ? <Check /> : <Close />;
};

export const UserList: React.FC = () => {
  const [isUserUploadModalOpen, setIsUserUploadModalOpen] =
    React.useState(false);

  const handleModalClose = () => setIsUserUploadModalOpen(false);

  return (
    <>
      <InfiniteList
        actions={
          <UserActions openModal={() => setIsUserUploadModalOpen(true)} />
        }
        filters={UserFilters}
      >
        <WriteOnlyDatagrid bulkActionButtons={false}>
          <ClipboardField source="id" sortable={false} />
          <ClipboardField
            source="email"
            data-testid="emailColumn"
            sortable={false}
          />
          <TextField source="firstName" label="First Name" sortable={false} />
          <TextField source="lastName" label="Last Name" sortable={false} />
          <FunctionField
            label="Is Admin"
            render={computeAdminField}
            sortable={false}
          />
          <BooleanField source="isCoach" label="Is Coach" sortable={false} />
          <BooleanField
            source="isTestUser"
            label="Is Test User"
            sortable={false}
          />
          <MultiTimeAgoField
            label="Last Activity"
            source="lastActivity"
            subfields={['heart', 'heartWeb']}
            sortable={false}
          />
          <FunctionField
            label="Platforms Connected"
            render={computeConnectedField}
            sortable={false}
          />
          <WriteOnlyEditButton />
          <ShowButton />
        </WriteOnlyDatagrid>
      </InfiniteList>
      <UserUploadModal
        isOpen={isUserUploadModalOpen}
        onClose={handleModalClose}
      />
    </>
  );
};
