import { useMemo } from 'react';

import { usePermissions } from 'react-admin';

import { canWrite } from '../admin/permissions';

const useCanWrite = () => {
  const { permissions } = usePermissions();
  // NOTE: permissions is itself memoized
  const userCanWrite = useMemo(() => canWrite(permissions), [permissions]);

  return userCanWrite;
};
export default useCanWrite;
