import { useState } from 'react';

import { useClientCoachesQuery as useCoachesQuery } from '../graphql/types';

const FIRST = 25;

const useClientCoachesQuery = (clientId: string | undefined) => {
  // "loading" will be true whenever the query hook is executed
  // but we want a loading state that is specific to when
  // `fetchMore` is executed, and this doesn't seem
  // to be something apollo client provides
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useCoachesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: clientId!, // see skip
      input: {
        first: FIRST,
      },
    },
    skip: !clientId,
  });

  const coaches = data?.client?.coaches.edges.map((edge) => edge.node);
  const coachCount = data?.client?.coaches?.count;

  const hasNextPage = data?.client?.coaches?.pageInfo?.hasNextPage;

  const loadMore = async () => {
    if (!hasNextPage) {
      return;
    }

    const endCursor = data?.client?.coaches?.pageInfo?.endCursor;

    setIsFetchingMore(true);

    await fetchMore({
      variables: {
        id: clientId,
        input: { first: FIRST, after: endCursor },
      },
    });

    setIsFetchingMore(false);
  };

  return {
    data,
    loadMore,
    coachCount,
    coaches,
    fetch,
    refetch,
    error,
    loading,
    hasNextPage,
    isFetchingMore,
  };
};

export default useClientCoachesQuery;
