import React from 'react';

import {
  FunctionField,
  FunctionFieldProps,
  SelectArrayInput,
  SelectArrayInputProps,
} from 'react-admin';

import { useGetActivityGenresQuery } from '../../../graphql/types';

function useContentGenres() {
  const { data, loading: genresLoading } = useGetActivityGenresQuery();
  const genres =
    data?.activityGenres.map(({ name, value }) => ({
      name,
      id: value,
    })) ?? [];
  return { genres, genresLoading };
}

export const GenresEdit: React.FC<SelectArrayInputProps> = ({
  label,
  disabled,
  ...rest
}) => {
  const { genres, genresLoading } = useContentGenres();
  return (
    <SelectArrayInput
      {...rest}
      label={genresLoading ? 'Loading...' : label}
      choices={genres}
      disabled={genresLoading || disabled}
    />
  );
};

export const DisplayGenres: React.FC<Omit<FunctionFieldProps, 'render'>> = (
  props,
) => {
  const { genres, genresLoading } = useContentGenres();
  return (
    <FunctionField
      {...props}
      render={(record?: Record<string, any>) => {
        const filteredGenres =
          genres.filter((genre) =>
            record?.qualifyingGenres.includes(genre.id),
          ) ?? [];

        if (genresLoading) {
          return 'Loading...';
        }
        if (filteredGenres.length === 0) {
          return 'All genres';
        }
        return filteredGenres.map(({ name }) => name).join(', ');
      }}
    />
  );
};
