import * as React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Tab, useRecordContext } from 'react-admin';

import { AdminUserDetailsFragment } from '../../../../graphql/types';
import useUserAuditLogsQuery from '../../../../hooks/useUserAuditLogsQuery';
import { dateAndTime } from '../../../../utils/date';
import PaginationWaypoint from '../../../reusable/PaginationWaypoint';

type AuditLogPrincipal =
  | {
      __typename: 'MachineClient';
      id: string;
    }
  | {
      __typename: 'User';
      id: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
    };

const JsonView: React.FC<{ data: any }> = ({ data }) => {
  const [open, setOpen] = React.useState(false);

  if (!open) {
    return (
      <Typography onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
        View
      </Typography>
    );
  } else {
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
        <Typography onClick={() => setOpen(false)} sx={{ cursor: 'pointer' }}>
          Hide
        </Typography>
      </div>
    );
  }
};

const getPrincipalInfo = (principal: AuditLogPrincipal) => {
  switch (principal.__typename) {
    case 'User':
      return `${principal.firstName} ${principal.lastName} <${principal.id}>`;
    case 'MachineClient':
      return 'System';
    default:
      return 'Unknown';
  }
};

export const UserAuditLogsTab: React.FC = () => {
  const record = useRecordContext<AdminUserDetailsFragment>();
  const { logs, loadMore, hasNextPage, loading } = useUserAuditLogsQuery(
    record?.id,
  );

  return (
    <Tab label="Audit Logs">
      <TableContainer sx={{ maxHeight: '90vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Principal</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs?.map((log) => (
              <TableRow key={log.id}>
                <TableCell>{log.action}</TableCell>
                <TableCell>{dateAndTime(log.createdAt)}</TableCell>
                <TableCell>{getPrincipalInfo(log.principal)}</TableCell>
                <TableCell>
                  <JsonView data={log.data} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>Loading...</Typography>
        </Box>
      )}
      {hasNextPage && <PaginationWaypoint callback={loadMore} />}
    </Tab>
  );
};
