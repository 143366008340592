import React from 'react';

import {
  ImageField,
  ImageInput,
  ImageInputProps,
  Loading,
  useInput,
} from 'react-admin';

import { Prefix } from '../../../graphql/types';
import { useUploadFile } from '../../../hooks/useUploadFile';

export interface ImageUploadInputProps extends ImageInputProps {
  prefix: Prefix;
}

const ImageUploadInput: React.FC<ImageUploadInputProps> = ({
  prefix,
  source,
  ...props
}) => {
  const [loading, setLoading] = React.useState(false);
  const { field } = useInput({ source });
  const { uploadFile } = useUploadFile();

  const handleChange = React.useCallback(async (value?: File) => {
    if (value instanceof File) {
      setLoading(true);
      const { key } = await uploadFile(value, prefix);
      field.onChange(key);
    } else {
      field.onChange(null);
    }
    setLoading(false);
  }, []);

  return (
    <ImageInput
      source={`url.${source}`}
      onChange={handleChange}
      accept={{
        'image/*': ['.jpeg', '.jpg', '.png'],
      }}
      {...props}
    >
      {loading ? <Loading /> : <ImageField source="src" title="title" />}
    </ImageInput>
  );
};

export default ImageUploadInput;
