import React, { createElement } from 'react';

import '@js-joda/locale_en-us';
import '@js-joda/timezone';
import { configure } from '@teamexos/fit-shared';
import { createRoot } from 'react-dom/client';

import Providers from '../Providers';
import config from '../aws-exports';
import '../components/layout.css';

import { BrowserRouter } from './routes';

configure(config[process.env.GATSBY_ENV_LEVEL ?? 'dev']);

const RenderIt = () => (
  <Providers>
    <BrowserRouter />
  </Providers>
);

let container: HTMLElement | null;

document.addEventListener('DOMContentLoaded', () => {
  if (!container) {
    container = document.getElementById('root');
    if (container) {
      const root = createRoot(container);
      root.render(createElement(RenderIt));
    }
  }
});
