import React, { useEffect } from 'react';

import { CheckCircle, ContentCopy } from '@mui/icons-material';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { FunctionField, FunctionFieldProps, TextField } from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { GREEN400 } from '../../../theme';

const ClipboardField: React.FC<
  Omit<FunctionFieldProps, 'render'> & { fontSize?: number }
> = ({ source = '', fontSize = 13, ...rest }) => {
  const [copied, setCopied] = React.useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 1000);
      return () => clearTimeout(timer);
    }

    return noop;
  });

  return (
    <FunctionField
      {...rest}
      aria-label="Copy to clipboard"
      render={(record: any) => {
        const text = get(record, source);
        return (
          <div>
            <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
              <div
                title="Copy to clipboard"
                style={{
                  display: 'flex',
                  cursor: 'copy',
                  alignItems: 'center',
                }}
              >
                <TextField
                  record={record}
                  source={source}
                  sortable={false}
                  style={{ fontFamily: 'monospace', fontSize }}
                />
                {!copied && (
                  <ContentCopy
                    fontSize="small"
                    style={{ marginLeft: 8, fontSize }}
                  />
                )}
                {copied && (
                  <CheckCircle
                    fontSize="small"
                    style={{ marginLeft: 8, color: GREEN400, fontSize }}
                  />
                )}
              </div>
            </CopyToClipboard>
          </div>
        );
      }}
    />
  );
};

export default ClipboardField;
