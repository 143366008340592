import React from 'react';

import { ZoneId } from '@js-joda/core';
import {
  BooleanField,
  BooleanInput,
  Create,
  Edit,
  Filter,
  ReferenceArrayField,
  ReferenceField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import AutocompleteClientInput from './components/AutocompleteClientInput';
import ClipboardField from './components/ClipboardField';
import InfiniteList from './components/InfiniteList';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyDeleteButton } from './components/WriteOnlyDeleteButton';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const timezoneChoices = ZoneId.getAvailableZoneIds()
  .sort()
  .map((zone) => ({
    id: zone,
    name: zone,
  }));

export const StudioFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export const StudioList: React.FC = () => (
  <InfiniteList filters={<StudioFilter />}>
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <ClipboardField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="client.name" label="Client Name" sortable={false} />
      <BooleanField source="isVirtual" label="Virtual" sortable={false} />
      <BooleanField source="isArchived" label="Archived" sortable={false} />
      <WriteOnlyEditButton />
      <ShowButton />
      <WriteOnlyDeleteButton mutationMode="undoable" />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

export const StudioShow: React.FC = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Studio Name" />
      <TextField source="mboStudioId" label="MBO Studio ID" />
      <TextField source="mboServiceId" label="MBO Service ID" />
      <TextField source="timezone" label="Timezone" />
      <BooleanField source="isCentral" label="Is Central" />
      <BooleanField source="isVirtual" label="Is Virtual" />
      <BooleanField
        source="isArchived"
        label="Is Archived: when this field is true, we will not create MBO profiles for members in this studio"
      />
      <ReferenceField source="client.id" reference="Client" label="Client">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceArrayField
        source="locations.ids"
        reference="CoachLocation"
        label="Locations"
      >
        <WriteOnlyDatagrid>
          <TextField source="name" />
          <ShowButton />
        </WriteOnlyDatagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export const StudioEdit: React.FC = () => (
  <Edit mutationMode="pessimistic" redirect={false}>
    <SimpleForm>
      <TextInput source="name" label="Studio Name" />
      <TextInput source="mboStudioId" label="MBO Studio ID" />
      <TextInput source="mboServiceId" label="MBO Service ID" />
      <SelectInput
        source="timezone"
        label="Timezone"
        choices={timezoneChoices}
      />
      <AutocompleteClientInput source="client.id" label="Client" />
      <BooleanInput source="isVirtual" label="Virtual" />
      <BooleanInput source="isCentral" label="Central" />
      <BooleanInput
        source="isArchived"
        label="Archived: when this field is true, we will not create MBO profiles for members in this studio"
      />
    </SimpleForm>
  </Edit>
);

export const StudioCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" label="Studio Name" />
      <TextInput source="mboStudioId" label="MBO Studio ID" />
      <TextInput source="mboServiceId" label="MBO Service ID" />
      <SelectInput
        source="timezone"
        label="Timezone"
        choices={timezoneChoices}
      />
      <AutocompleteClientInput source="client.id" label="Client" />
      <BooleanInput source="isVirtual" label="Virtual" />
      <BooleanInput source="isCentral" label="Central" />
    </SimpleForm>
  </Create>
);
