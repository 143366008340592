import * as React from 'react';

import { LocalDate, ZonedDateTime } from '@js-joda/core';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ShowButton, Tab, useRecordContext } from 'react-admin';
import styled from 'styled-components';

import {
  AdminUserDetailsFragment,
  LastActivityBasicsFragment,
  UserActivityAttributionType,
  UserActivityBasicsFragment,
  UserActivityType,
} from '../../../../graphql/types';
import { dateAndTime, getTimeSince, shortDate } from '../../../../utils/date';
import { Divider } from '../../components/Divider';

export const getMostRecentActivityDate = (
  lastActivity: LastActivityBasicsFragment | null = {},
) => {
  if (!lastActivity) {
    return null;
  }

  const { heart, heartWeb } = lastActivity;

  const heartDate = heart ? ZonedDateTime.parse(heart) : null;
  const heartWebDate = heartWeb ? ZonedDateTime.parse(heartWeb) : null;

  if (!heartDate && !heartWebDate) {
    return null;
  }

  if (!heartDate) {
    return heartWebDate;
  }

  if (!heartWebDate) {
    return heartDate;
  }

  return heartDate.isAfter(heartWebDate) ? heartDate : heartWebDate;
};

export function getLastActivity(
  date?: ZonedDateTime | null,
  today?: LocalDate,
) {
  if (!date || !today) {
    return 'n/a';
  }

  return getTimeSince(date.toLocalDate(), today);
}

export const tagTextFrom = (activity: UserActivityBasicsFragment) => {
  if (activity.genre === 'Sleep') {
    return 'Sleep';
  }

  switch (activity.type) {
    case UserActivityType.Article:
      return 'Article';
    case UserActivityType.Assessment:
      if (activity.attributionType === UserActivityAttributionType.Monitoring) {
        return 'Monitoring';
      }
      return 'Assessment';
    case UserActivityType.Audio:
    case UserActivityType.Aod:
      return 'Audio';
    case UserActivityType.Encore:
      return 'Encore';
    case UserActivityType.InPerson:
      return 'Gym';
    case UserActivityType.Virtual:
      return 'Live';
    case UserActivityType.Video:
    case UserActivityType.Vod:
      return 'Video';
    default:
      return 'Other';
  }
};

const ProfileLabel = styled(Typography)`
  font-weight: bold;
`;

const CustomPlanDetails: React.FC<{
  plan: AdminUserDetailsFragment['currentCustomPlan'];
}> = ({ plan }) => {
  if (!plan) {
    return <Typography>None</Typography>;
  }

  return (
    <Box>
      <Typography>Name: {plan.name}</Typography>
      <Typography>
        {shortDate(LocalDate.parse(plan.startDate))} -{' '}
        {shortDate(LocalDate.parse(plan.endDate))}
      </Typography>
      <Typography>{plan.planItems.count} plan items</Typography>
    </Box>
  );
};

export const MemberProfileTab: React.FC = () => {
  const record = useRecordContext<AdminUserDetailsFragment>();

  if (!record) {
    return null;
  }

  return (
    <Tab label="Profile">
      <Box>
        <ProfileLabel variant="body2">Registration Date</ProfileLabel>
        <Typography>{dateAndTime(record.createdAt)}</Typography>
      </Box>
      <Box>
        <ProfileLabel variant="body2">Last Active</ProfileLabel>
        <Typography>
          {getLastActivity(
            getMostRecentActivityDate(record.lastActivity),
            LocalDate.now(),
          )}
        </Typography>
      </Box>
      <Box>
        <ProfileLabel variant="body2">Pronouns</ProfileLabel>
        <Typography>{record.pronouns || 'None'}</Typography>
      </Box>
      <Box>
        <ProfileLabel variant="body2">Selected Country</ProfileLabel>
        <Typography>{record.countryCode ?? 'None'}</Typography>
      </Box>
      <Box>
        <ProfileLabel variant="body2">Current Program</ProfileLabel>
        <Typography>
          {record.currentProgram?.program?.title ?? 'None'}
        </Typography>
      </Box>
      <Box>
        <ProfileLabel variant="body2">Current Practice</ProfileLabel>
        <Typography>
          {record.currentPractice?.practiceProgram?.title ?? 'None'}
        </Typography>
      </Box>

      <Box>
        <ProfileLabel variant="body2">Current Gameplan Phase</ProfileLabel>
        <CustomPlanDetails plan={record.currentCustomPlan} />
      </Box>
      <Divider />
      <Box>
        <ProfileLabel variant="h5">
          Challenges ({record.challenges.count} total)
        </ProfileLabel>
        {record.challenges.count === 0 && <Typography>None</Typography>}
        {record.challenges.count > 0 && (
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableHead>
            <TableBody>
              {record.challenges?.nodes.map((challenge) => (
                <TableRow>
                  <TableCell>{challenge.title}</TableCell>
                  <TableCell>
                    <ShowButton resource="Challenge" record={challenge} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
      <Divider />
      <Box>
        <Typography variant="h5">
          Studios ({record.studios.count} total)
        </Typography>
        {record.studios.count === 0 && <Typography>None</Typography>}
        {record.studios.count > 0 && (
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableHead>
            <TableBody>
              {record.studios?.nodes.map((studio) => (
                <TableRow>
                  <TableCell>{studio.name}</TableCell>
                  <TableCell>
                    <ShowButton resource="Studio" record={studio} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
      <Divider />
      <Box>
        <Typography variant="h5">
          Recent App Activity ({record.userActivity.count} total)
        </Typography>
        {record.userActivity.count === 0 && <Typography>None</Typography>}
        {record.userActivity.count > 0 && (
          <Table>
            <TableHead>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Title</TableCell>
            </TableHead>
            <TableBody>
              {record.userActivity?.nodes.map((activity) => (
                <TableRow>
                  <TableCell>{tagTextFrom(activity)}</TableCell>
                  <TableCell>{dateAndTime(activity.startTime)}</TableCell>
                  <TableCell>{activity.title}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Tab>
  );
};
