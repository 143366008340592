import React from 'react';

import {
  Create,
  Edit,
  Filter,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import AutocompleteClientInput from './components/AutocompleteClientInput';
import ClipboardField from './components/ClipboardField';
import InfiniteList from './components/InfiniteList';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyDeleteButton } from './components/WriteOnlyDeleteButton';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

export const DomainFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export const DomainList: React.FC = () => (
  <InfiniteList filters={<DomainFilter />}>
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <ClipboardField source="id" sortable={false} />
      <TextField source="domain" sortable={false} />
      <ReferenceField
        source="client.id"
        reference="Client"
        label="Client"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <WriteOnlyEditButton />
      <ShowButton />
      <WriteOnlyDeleteButton mutationMode="undoable" />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

export const DomainShow: React.FC = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="domain" label="Domain Name" />
      <ReferenceField source="client.id" reference="Client" label="Client">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const DomainEdit: React.FC = () => (
  <Edit redirect={false}>
    <SimpleForm>
      <TextInput source="id" InputProps={{ disabled: true }} />
      <TextInput source="domain" label="Domain Name" />
      <AutocompleteClientInput source="client.id" label="Client" />
    </SimpleForm>
  </Edit>
);

export const DomainCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="domain" label="Domain Name" />
      <AutocompleteClientInput source="client.id" label="Client" />
    </SimpleForm>
  </Create>
);
