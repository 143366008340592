import React from 'react';

import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from 'react-admin';

const AutocompleteStudioInput: React.FC<
  Omit<ReferenceInputProps, 'reference' | 'filterToQuery'>
> = ({ source, label, filter, ...props }) => (
  <ReferenceInput {...props} source={source} filter={filter} reference="Studio">
    <AutocompleteInput
      label={label}
      optionText="name"
      filter="name"
      filterToQuery={(name: string) => ({
        name,
      })}
    />
  </ReferenceInput>
);

export default AutocompleteStudioInput;
