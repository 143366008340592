import { useContext } from 'react';

import { SplitContext } from '@splitsoftware/splitio-react';

import { useSelfQuery } from '../graphql/types';

export enum SplitFlags {
  SPA_TEST_DATA_SERVICE = 'spa-test-data-service',
}

type FeatureFlags = Record<SplitFlags, boolean>;

export const useFeatureFlags = () => {
  const { client, isReady } = useContext(SplitContext) || {};
  const { data } = useSelfQuery({
    fetchPolicy: 'cache-first',
  });
  const { id: userId = '', isAdmin = false, email = '' } = data?.self ?? {};

  const attributes = {
    userId,
    isAdmin,
    email,
  };

  const treatments =
    client?.getTreatmentsWithConfig(Object.values(SplitFlags), attributes) ??
    {};
  const normalizedTreatments = {} as FeatureFlags;

  Object.keys(treatments).forEach((id) => {
    normalizedTreatments[id as unknown as SplitFlags] =
      treatments[id].treatment === 'on';
  });

  return { isReady, flags: normalizedTreatments };
};

export const useGetFlagFromEmail = (): ((
  flag: string,
  email: string,
) => Promise<Boolean>) => {
  const { client } = useContext(SplitContext) || {};
  return async (flag, email) =>
    client?.getTreatment(flag, {
      email,
    }) === 'on';
};
