import React, { useState } from 'react';

import { Chat, Group, Warning } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Form, Link, SaveButton, ShowButton, TextInput } from 'react-admin';
import styled from 'styled-components';

import {
  AdminUserDetailsFragment,
  useBulkReassignCoachMembersMutation,
  useRemoveCoachMembersMutation,
} from '../../../graphql/types';
import useCanWrite from '../../../hooks/useCanWrite';
import { useCoachMembersQuery } from '../../../hooks/useCoachMembersQuery';
import { STATUS_ERROR } from '../../../theme';
import Modal, { ModalContent, ModalDescription, ModalTitle } from '../../Modal';
import PaginationWaypoint from '../../reusable/PaginationWaypoint';

import AutocompleteUserInput from './AutocompleteUserInput';

const ButtonContainer = styled.div({
  display: 'flex',
  fontFamily: 'Inter',
  fontWeight: 500,
  justifyContent: 'flex-end',
  marginTop: '1rem',
});

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onRemove,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalContent>
      <ModalTitle>Are you sure you want to remove members?</ModalTitle>
      <ButtonContainer>
        <Button style={{ opacity: 0.38 }} onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onRemove}>
          Remove
        </Button>
      </ButtonContainer>
    </ModalContent>
  </Modal>
);

interface BulkReassignModalProps {
  isOpen: boolean;
  onClose: () => void;
  coach: AdminUserDetailsFragment;
  listOfUserIds: string[];
  reassignAllUsers: boolean;
  setListOfUserIds: (ids: string[]) => void;
}
export const BulkReassignModal: React.FC<BulkReassignModalProps> = ({
  isOpen,
  onClose,
  coach,
  listOfUserIds = [],
  reassignAllUsers = false,
  setListOfUserIds,
}) => {
  const [newCoachId, setNewCoachId] = React.useState<null | string>(null);

  const [bulkReassignCoachMembers, { loading, error, data, reset }] =
    useBulkReassignCoachMembersMutation({
      variables: {
        oldCoachId: coach.id,
        newCoachId: newCoachId ?? '',
        userIds: listOfUserIds,
        reassignAllUsers,
      },
    });

  const onListOfUserIdsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target ?? { value: '' };
    setListOfUserIds(value.split(',').map((id) => id.trim()));
  };
  const onSelect = (selectedCoachId: string) => {
    setNewCoachId(selectedCoachId.length ? selectedCoachId : null);
  };
  const handleSubmit = async () => {
    await bulkReassignCoachMembers();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalContent style={{ height: 'auto' }}>
        <ModalTitle>Bulk Reassign Members to new Coach</ModalTitle>
        {loading && <ModalDescription>Loading...</ModalDescription>}
        {error && (
          <>
            <ModalDescription>
              There was an error processing this request. Please make sure that
              all ID&apos;s are valid ID&apos;s
            </ModalDescription>
            <ModalDescription>{error.message}</ModalDescription>
            <ButtonContainer>
              <Button onClick={handleClose}>Close</Button>
            </ButtonContainer>
          </>
        )}
        {data && (
          <>
            <ModalDescription>
              Successfully reassigned{' '}
              {data.bulkReassignCoachMembers.userCountReassigned} members
            </ModalDescription>
            {!reassignAllUsers && (
              <Input disabled multiline value={listOfUserIds.join(', ')} />
            )}
            {data.bulkReassignCoachMembers.userIdsNotReassigned.length > 0 && (
              <>
                <ModalDescription>
                  We were unable to process the following users. Please check
                  that: <br />
                  1. users exist in the system <br />
                  2. users are assigned to {coach.email}
                </ModalDescription>
                <Form>
                  <TextInput
                    disabled
                    fullWidth
                    multiline
                    maxRows={5}
                    value={data.bulkReassignCoachMembers.userIdsNotReassigned.join(
                      ', ',
                    )}
                    source="id"
                  />
                </Form>
              </>
            )}
            <ButtonContainer>
              <Button onClick={handleClose}>Close</Button>
            </ButtonContainer>
          </>
        )}
        {!loading && !error && !data && (
          <>
            <ModalDescription>
              This change will only apply to members that are assigned to the
              following coach. Please take a moment to verify that the email and
              ID are correct.
            </ModalDescription>
            <ModalDescription>
              ID: <strong>{coach.id}</strong>
              <br />
              email: <strong>{coach.email}</strong>
            </ModalDescription>

            <Form
              onSubmit={handleSubmit}
              defaultValues={{ listOfMemberIds: '' }}
            >
              <ModalDescription>
                Find the coach that members will be assigned to.
              </ModalDescription>
              <AutocompleteUserInput
                inputProps={{
                  onChange: onSelect,
                  label: 'New Coach',
                }}
                filter={{ isCoach: true }}
                source="id"
              />

              {reassignAllUsers ? (
                <ModalDescription>
                  All members will be reassigned to the new coach.
                </ModalDescription>
              ) : (
                <>
                  <ModalDescription>
                    Please enter a comma separated list of Member IDs that
                    belong to the old coach that will be reassigned to the new
                    coach.
                  </ModalDescription>
                  <Input
                    multiline
                    fullWidth
                    maxRows={5}
                    onChange={onListOfUserIdsChange}
                    value={listOfUserIds.join(', ')}
                  />
                </>
              )}

              <ButtonContainer>
                <Button onClick={handleClose}>Cancel</Button>
                <SaveButton
                  color="error"
                  disabled={
                    !newCoachId ||
                    (listOfUserIds.length === 0 && !reassignAllUsers) ||
                    loading
                  }
                  label={`Reassign ${reassignAllUsers ? 'All' : listOfUserIds.length} Members`}
                  icon={<Group />}
                />
              </ButtonContainer>
            </Form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

interface ListCoachMembersProps {
  coach: AdminUserDetailsFragment;
}
const ListCoachMembers: React.FC<ListCoachMembersProps> = ({ coach }) => {
  const canWrite = useCanWrite();

  const {
    data,
    loading,
    error,
    hasNextPage,
    fetchMore,
    isFetchingMore,
    refetch,
  } = useCoachMembersQuery({ coachId: coach.id });

  const [reassignAllUsers, setReassignAllUsers] = React.useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [isBulkReassignModalOpen, setIsBulkReassignModalOpen] =
    React.useState(false);

  const [membersToReassign, setMembersToReassign] = useState<string[]>([]);

  const [removeCoachMembersMutation] = useRemoveCoachMembersMutation({
    variables: {
      coachId: coach.id,
      clientId: coach?.fitClient?.id ?? '',
    },
  });

  const onRemoveHandler = () => {
    removeCoachMembersMutation();
    setConfirmModalOpen(false);
  };

  const toggleMember = (memberId: string) => {
    if (membersToReassign.includes(memberId)) {
      setMembersToReassign(membersToReassign.filter((id) => id !== memberId));
    } else {
      setMembersToReassign([...membersToReassign, memberId]);
    }
  };

  const handleCloseModal = () => {
    refetch();
    setMembersToReassign([]);
    setIsBulkReassignModalOpen(false);
    setReassignAllUsers(false);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Total Members: {data?.user?.coachData?.coachUsers.count}</p>
        {canWrite && (
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsBulkReassignModalOpen(true)}
            >
              Bulk Reassign Members
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '16px 0' }}
              onClick={() => setConfirmModalOpen(true)}
            >
              Remove members
            </Button>
          </div>
        )}
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {canWrite && (
                <TableCell>
                  Reassign All{' '}
                  <Checkbox
                    checked={reassignAllUsers}
                    onChange={() => {
                      setReassignAllUsers(!reassignAllUsers);
                    }}
                  />
                </TableCell>
              )}
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Alerts</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={canWrite ? 6 : 5}>Loading...</TableCell>
              </TableRow>
            )}
            {error && (
              <TableRow>
                <TableCell colSpan={canWrite ? 6 : 5}>
                  Error: {error.message}
                </TableCell>
              </TableRow>
            )}
            {!error &&
              data?.user?.coachData?.coachUsers.edges.map(({ node }) => (
                <TableRow key={node.id}>
                  {canWrite && (
                    <TableCell>
                      <Checkbox
                        checked={
                          membersToReassign.includes(node.id) ||
                          reassignAllUsers
                        }
                        onChange={() => {
                          toggleMember(node.id);
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell>{node.id}</TableCell>
                  <TableCell>{node.firstName}</TableCell>
                  <TableCell>{node.lastName}</TableCell>
                  <TableCell>{node.email}</TableCell>
                  <TableCell>
                    {!coach.coachData?.clients.nodes.find(
                      (client) => client.id === node.fitClient?.id,
                    ) && (
                      <Link to={`/User/${coach.id}/3`}>
                        <Tooltip
                          title={`Coach not assigned to member's client: ${node.fitClient?.name}`}
                        >
                          <Warning sx={{ color: STATUS_ERROR }} />
                        </Tooltip>
                      </Link>
                    )}
                    {node.fitCoachChat?.isArchived && (
                      <Link to={`/ChatRoom/${node.fitCoachChat.id}/3`}>
                        <Tooltip title="Primary chat is archived">
                          <Warning sx={{ color: STATUS_ERROR }} />
                        </Tooltip>
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    <ShowButton record={node} resource="User" />
                    {node.fitCoachChat && (
                      <>
                        /
                        <ShowButton
                          record={node.fitCoachChat}
                          resource="ChatRoom"
                          label="Chat"
                          icon={<Chat />}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasNextPage && isFetchingMore && (
        <div style={{ textAlign: 'center' }}>Loading...</div>
      )}
      {hasNextPage && !isFetchingMore && (
        <PaginationWaypoint callback={fetchMore} />
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onRemove={onRemoveHandler}
      />
      <BulkReassignModal
        isOpen={isBulkReassignModalOpen}
        onClose={handleCloseModal}
        coach={coach}
        listOfUserIds={membersToReassign}
        setListOfUserIds={setMembersToReassign}
        reassignAllUsers={reassignAllUsers}
      />
    </>
  );
};

export default ListCoachMembers;
