import * as React from 'react';

import {
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { App } from '../../../graphql/types';

export const UserCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="firstName" id="firstName" />
      <TextInput source="lastName" id="lastName" />
      <TextInput source="email" id="email" />
      <BooleanInput source="isCoach" id="isCoach" />
      <BooleanInput source="isTestUser" id="isTestUser" />
      <SelectInput
        source="app"
        choices={Object.entries(App).map(([name, id]) => ({ id, name }))}
        defaultValue={App.Prince}
      />
    </SimpleForm>
  </Create>
);
