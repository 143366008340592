import * as React from 'react';

import { DeleteButton, DeleteButtonProps, RaRecord } from 'react-admin';

import useCanWrite from '../../../hooks/useCanWrite';

/**
 * Thin wrapper around the standard ReactAdmin DeleteButton.
 * Hides the button if the user may not write.
 */
export const WriteOnlyDeleteButton = (props: DeleteButtonProps<RaRecord>) => {
  const canWrite = useCanWrite();
  return canWrite && <DeleteButton {...props} />;
};
