import {
  DayOfWeek,
  LocalDate,
  TemporalAdjusters,
  ZoneId,
  ZonedDateTime,
} from '@js-joda/core';
import { DemoUserInput } from '@teamexos/test-data-service';

import {
  CoachSessionType,
  CoachTouchPointType,
  PlanItemType,
  UserGoalType,
} from '../../../../graphql/types';

export const getCoachTrainingMembers = ({
  coachId,
  nutritionistId,
}: {
  coachId: string;
  nutritionistId?: string;
}): DemoUserInput[] => {
  const now = ZonedDateTime.now(ZoneId.UTC);

  return [
    {
      firstName: 'Ashley',
      lastName: 'Phillip',
      createdAt: ZonedDateTime.parse('2022-07-01T00:00:00.0Z'),
      coachId,
      pronoun: 'She / Her',
      nutritionistId,
      lastAppActivity: ZonedDateTime.now(ZoneId.UTC),
      shouldStarUser: true,
      shouldCreateHistoricalData: true,
      shouldAssignPractice: true,
      shouldCreateSessions: true,
      shouldSendMessage: true,
      videos: [
        '6f453678-2742-4975-a830-d73156d4f5d2',
        'a5c667e5-2d4a-4ff1-bcef-6902c8a1cc17',
      ],
      dailyActionConfig: {
        type: CoachTouchPointType.OneWeekPostSessionCheckIn,
        due: now,
      },
      onboardingSurveys: {
        birthday: LocalDate.now().minusYears(38).toString(),
        height: 5.5,
        weight: 128,
        identity: 1,
        goals: 0,
        motivation: 3,
        barriers: [0, 2, 6],
        passions: [18, 12, 15],
        experience: 2,
        movement: 3,
        reach: 4,
        nutritionEnergy: 2,
        water: 4,
        sleepQuantity: 2,
        sleepStartTime: 1,
        sleepEndTime: 3,
        negativeThoughtPatterns: 3,
        mindsetEnergy: 3,
        flowState: 2,
      },
      note: {
        coachId,
        title: 'Coaching Session Note',
        content: `<p>Mid-Level Manager. Works hybrid schedule. Travels 2-3x/month to visit different sites. Has a 5-year old daughter, Sophia.&nbsp;</p><p></p><p><strong>Goal</strong></p><ul><li><p>Avid runner since college. Competes in a marathon each year</p></li><li><p>Next marathon is 4 months away. Looking for a new PR <strong>(under 4 hrs)</strong></p></li></ul><p></p><p><strong>Typical run schedule:&nbsp;</strong></p><ul><li><p>4x/week</p></li><li><p>1 track day, 2 medium distance days, 1 long day</p></li></ul><p></p><p>*Felt fatigued lately. Having trouble going to sleep and sometimes wakes up in middle of the night.&nbsp;</p><p>*Right knee pain during her running lately</p><p>*Has never hired a trainer or endurance coach. Wondering if we can help her set a PR</p><p></p><p><strong>&nbsp;Typical day of eating:&nbsp;</strong></p><ul><li><p>Breakfast: 2 eggs, ½ cup oatmeal, fresh berries, coffee, water</p></li><li><p>Snack AM: greek yogurt, granola, and/or protein shake smoothie</p></li><li><p>Lunch: Spinach salad with grilled chicken, vegetable variety, avocado oil,water</p></li><li><p>Snack PM: grabs for salty or sweet snack like popcorn or chocolate covered almonds, coffee or tea</p></li><li><p>Dinner: Fresh fish, sweet potatoes, and green vegetable, water</p></li></ul>`,
      },
    },
    {
      firstName: 'Paul',
      lastName: 'Jackson',
      createdAt: ZonedDateTime.parse('2021-06-01T00:00:00.0Z'),
      coachId,
      pronoun: 'He / Him',
      nutritionistId,
      lastAppActivity: ZonedDateTime.now(ZoneId.UTC).minusDays(1),
      shouldStarUser: true,
      videos: ['a05dfa26-ee94-483f-aee3-658f74457c15'],
      shouldCreateSessions: true,
      shouldSendMessage: true,
      dailyActionConfig: {
        type: CoachTouchPointType.TrackAttendance,
        due: now,
      },
      onboardingSurveys: {
        birthday: LocalDate.now().minusYears(45).toString(),
        height: 5.83,
        weight: 300,
        identity: 0,
        goals: 1,
        motivation: 3,
        barriers: [0, 2, 6],
        passions: [2, 3],
        experience: 1,
        movement: 1,
        reach: 2,
        nutritionEnergy: 1,
        water: 2,
        sleepQuantity: 1,
        sleepStartTime: 2,
        sleepEndTime: 3,
        negativeThoughtPatterns: 1,
        mindsetEnergy: 3,
        flowState: 1,
      },
      note: {
        coachId,
        title: 'Coaching Session Notes',
        content:
          '<p>Works in human resources. Works onsite 5x/week.&nbsp;</p><p>Played football growing up</p><p>Gained 100 lbs in last 15 years since getting married &amp; working stressful desk job</p><p>Diagnosed with type II diabetes five years ago.</p><p></p><p><strong>Family</strong></p><ul><li><p>Wife x 15 years</p></li><li><p>2 sons, 14 &amp; 10 years old ~ AJ and Shawn</p></li></ul><p><strong>Goals&nbsp;</strong></p><ul><li><p>Wants to be old self 100+ lbs ago. Feels horrible &amp; sluggish. Low confidence. Where should I start?&nbsp;</p></li><li><p>Has tried onsite fitness center. Does elliptical and has tried group ex class.&nbsp;</p></li><li><p>Use to go on walks with wife &amp; kids for 20 minutes after dinner most nights. Got out of the habit once he started going back to office after covid.&nbsp;</p></li></ul><p><strong>Diet</strong></p><ul><li><p>Eats Subway most days for lunch</p></li><li><p>Enjoys cooking dinners at home with wife and songs</p></li><li><p>Spaghetti, taco tuesdays, burgers on the grill, bbq chicken</p></li></ul>',
      },
    },
    {
      firstName: 'Anitha',
      lastName: 'Reddy',
      pronoun: 'She / Her',
      createdAt: ZonedDateTime.parse('2023-06-01T00:00:00.0Z'),
      coachId,
      nutritionistId,
      lastAppActivity: ZonedDateTime.now(ZoneId.UTC).minusDays(2),
      shouldStarUser: true,
      shouldCreateSessions: true,
      shouldSendMessage: true,
      videos: [
        'a50837b8-bbae-4d01-9304-9d0663d9129e',
        'e8655f11-874b-4d80-b68c-02c9a4edaf3d',
        'b44cc6f0-06b5-4c1d-a0d4-9d55a3581ecf',
        '3f6cd331-b966-44c7-9a14-3c7864607a6b',
        '88dad17b-2ae4-4fde-bcb2-97513bd8291d',
        '3912f72a-62a1-409a-ae2d-196ee9316d9c',
        '49886512-8317-44dd-9be5-81912068a0e3',
        '4f2a6caf-4319-4343-936c-a9e3abd37999',
        'cbb4084d-5979-4f8d-b9f7-59ef6c33f1f4',
        'f6f298b7-8f6a-401e-a127-a13b75cd722d',
      ],
      dailyActionConfig: {
        type: CoachTouchPointType.SessionReminder,
        due: now,
      },
      onboardingSurveys: {
        birthday: LocalDate.now().minusYears(26).toString(),
        height: 5.17,
        weight: 100,
        identity: 1,
        goals: 1,
        motivation: 1,
        barriers: [5],
        passions: [7, 11],
        experience: 0,
        movement: 0,
        reach: 4,
        nutritionEnergy: 4,
        water: 2,
        sleepQuantity: 2,
        sleepStartTime: 2,
        sleepEndTime: 3,
        negativeThoughtPatterns: 3,
        mindsetEnergy: 3,
        flowState: 4,
      },
      note: {
        coachId,
        title: 'Coaching Session Note',
        content: `<p>Works in creative design and marketing. Works from home</p><p>Not active and has only done cardio for workouts in the past.&nbsp; 5 years ago did a 5k with a friend.&nbsp;</p><p></p><p><strong>Goals:&nbsp;</strong></p><ul><li><p>Tone up and lose weight &amp; improve cholesterol levels.&nbsp;</p></li><li><p>Wants to learn how to be healthier and start a diet &amp; fitness routine</p></li><li><p>Wants to try strength training but is intimidated by the gym</p></li></ul><p>Available 2-3x per week for 30 minutes for workouts</p><p>Eats out for most meals. Bagel or oatmeal for breakfast.&nbsp;</p><p>Fruit and granola bar for snack at work</p>`,
      },
    },
  ];
};

export const getHpCoachTrainingMembers = ({
  coachId,
}: {
  coachId: string;
}): DemoUserInput[] => {
  const zaphodsPlanStartDate = LocalDate.now().with(
    TemporalAdjusters.previousOrSame(DayOfWeek.SUNDAY),
  );

  return [
    {
      firstName: 'Arthur',
      lastName: 'Dent',
      createdAt: ZonedDateTime.parse('2023-06-01T00:00:00.0Z'),
      coachId,
      hasHighPerformanceCoaching: true,
      shouldCreateHistoricalData: true,
      shouldStarUser: true,
      userGoal: {
        type: UserGoalType.EnergyLevels,
        description: 'Have more energy',
      },
      onboardingSurveys: {
        birthday: LocalDate.now().minusYears(38).toString(),
        height: 5.92,
        weight: 180,
        identity: 1,
        goals: 0,
        motivation: 2,
        barriers: [0, 2, 4],
        passions: [7, 8, 9],
      },
    },
    {
      firstName: 'Zaphod',
      lastName: 'Beeblebrox',
      createdAt: ZonedDateTime.parse('2023-06-01T00:00:00.0Z'),
      coachId,
      hasHighPerformanceCoaching: true,
      shouldCreateHistoricalData: true,
      shouldStarUser: true,
      userGoal: {
        type: UserGoalType.PhysicalPerformance,
        description: 'Ultramarathon',
      },
      onboardingSurveys: {
        birthday: LocalDate.now().minusYears(38).toString(),
        height: 5.92,
        weight: 180,
        identity: 0,
        goals: 0,
        motivation: 2,
        barriers: [0, 3, 6],
        passions: [11, 10, 14],
      },
      assessments: {
        assigned: {
          surveyContentIds: [
            '7a4b706d-0a50-4365-8d88-3aa0594cc645',
            '08147068-1a73-451c-86b1-cf9294ac0019',
            '7c5aec40-7f6e-4128-bef5-613b57055265',
            '7482534f-3162-478a-8c7b-2c3da5da23eb',
            '897810ff-8139-47b9-a0c9-4d3602dd4ced',
            '82b84a12-3c79-4366-a0d6-6bbadab27927',
          ],
        },
        completed: [
          {
            surveyContentId: '7a4b706d-0a50-4365-8d88-3aa0594cc645',
            answers: [
              {
                selectedChoices: [1],
              },
              {
                selectedChoices: [2],
              },
              {
                selectedChoices: [3],
              },
              {
                selectedChoices: [3],
              },
            ],
          },
          {
            surveyContentId: '08147068-1a73-451c-86b1-cf9294ac0019',
            answers: [
              {
                selectedChoices: [2],
              },
              {
                selectedChoices: [1],
              },
              {
                selectedChoices: [3],
              },
              {
                selectedChoices: [3],
              },
            ],
          },
          {
            surveyContentId: '7c5aec40-7f6e-4128-bef5-613b57055265',
            answers: [
              {
                selectedChoices: [2],
              },
              {
                selectedChoices: [2],
              },
              {
                selectedChoices: [4],
              },
              {
                selectedChoices: [2],
              },
            ],
          },
          {
            surveyContentId: '7482534f-3162-478a-8c7b-2c3da5da23eb',
            answers: [
              {
                selectedChoices: [0],
              },
              {
                selectedChoices: [3],
              },
            ],
          },
          {
            surveyContentId: '897810ff-8139-47b9-a0c9-4d3602dd4ced',
            answers: [
              {
                selectedChoices: [3],
              },
              {
                selectedChoices: [4],
              },
              {
                selectedChoices: [0],
              },
            ],
          },
          {
            surveyContentId: '82b84a12-3c79-4366-a0d6-6bbadab27927',
            answers: [
              {
                selectedChoices: [0],
              },
              {
                selectedChoices: [4],
              },
              {
                selectedChoices: [4],
              },
              {
                selectedChoices: [3],
              },
              {
                selectedChoices: [3],
              },
            ],
          },
        ],
      },
      customPlans: [
        {
          isTest: true,
          name: 'Phase 1',
          startDate: zaphodsPlanStartDate.toString(),
          planItems: [
            // Week 1 Day 1
            {
              date: zaphodsPlanStartDate.toString(),
              type: PlanItemType.CustomTask,
              order: 0,
              title: 'Weekly Overview',
              component: 'Reflection',
              description:
                "Hey Zaphod!  Welcome to the first week of your Gameplan.  To help focus on developing your strength and power we'll have four days of training this week, supported with lots of great info and strategies on fueling, and sleep among other things.  Each of these have been selected based on the information you've provided me to help you take a big step towards being ready for your moment!  So choose to meet these with curiosity, and an open mind, and I'll reach out later this week to see how everything is going!",
            },
            // Week 1 Day 2
            {
              date: zaphodsPlanStartDate.plusDays(1).toString(),
              type: PlanItemType.ExosContent,
              order: 0,
              component: 'Training',
              contentId: '896bf098-b59c-439f-adc5-14ce998a0992',
            },
            {
              date: zaphodsPlanStartDate.plusDays(1).toString(),
              type: PlanItemType.ExosContent,
              order: 1,
              component: 'Fueling',
              contentId: '0c439519-636d-4f9c-92ae-f88676097e7d',
            },
            {
              date: zaphodsPlanStartDate.plusDays(1).toString(),
              type: PlanItemType.ExosContent,
              order: 2,
              component: 'Daily Movement',
              contentId: '9f4a91e5-f283-4d9c-8d57-e0d7a290d094',
            },
            // Week 1 Day 3
            {
              date: zaphodsPlanStartDate.plusDays(2).toString(),
              type: PlanItemType.ExosContent,
              order: 0,
              component: 'Training',
              contentId: '513c5e07-d5e7-4477-81c8-d52a4d1cddcb',
            },
            {
              date: zaphodsPlanStartDate.plusDays(2).toString(),
              type: PlanItemType.ExosContent,
              order: 1,
              component: 'Fueling',
              contentId: '4d5d400b-3d3d-444d-a2da-ee03a99a203b',
            },
            {
              date: zaphodsPlanStartDate.plusDays(2).toString(),
              type: PlanItemType.ExosContent,
              order: 2,
              component: 'Daily Movement',
              contentId: 'bb2e2c78-e075-45bb-94e7-83451adeae18',
            },

            // Week 1 Day 4
            {
              date: zaphodsPlanStartDate.plusDays(3).toString(),
              type: PlanItemType.ExosContent,
              order: 0,
              component: 'Sleep',
              contentId: 'e58168ff-8db0-4e60-9a94-2d77777d650d',
            },
            {
              date: zaphodsPlanStartDate.plusDays(3).toString(),
              type: PlanItemType.ExosContent,
              order: 1,
              component: 'Reflection',
              contentId: 'c3a0e928-0a08-410f-bffc-32e58d5cbeb5',
            },
            {
              date: zaphodsPlanStartDate.plusDays(3).toString(),
              type: PlanItemType.ExosContent,
              order: 2,
              component: 'Regulation',
              contentId: '897d2e88-1073-478b-a05b-a789f24e1637',
            },

            // Week 1 Day 5
            {
              date: zaphodsPlanStartDate.plusDays(4).toString(),
              type: PlanItemType.ExosContent,
              order: 0,
              component: 'Training',
              contentId: 'e87d7384-cc4c-4616-bcb6-fad4eb7f3cc4',
            },
            {
              date: zaphodsPlanStartDate.plusDays(4).toString(),
              type: PlanItemType.ExosContent,
              order: 1,
              component: 'Fueling',
              contentId: '6965b7f8-f697-46c2-8ca6-3b6758faf6e5',
            },
            {
              date: zaphodsPlanStartDate.plusDays(4).toString(),
              type: PlanItemType.CustomTask,
              order: 2,
              component: 'Sleep',
              title: 'Natural light',
              description:
                'Remember that natural light is an incredibly strong anchor for your circadian rhythm.  So make sure to get 2-10min before 10a, and another 2-10min around sunset as well.',
            },
            {
              date: zaphodsPlanStartDate.plusDays(4).toString(),
              type: PlanItemType.ExosContent,
              order: 3,
              component: 'Daily Movement',
              contentId: '2137d013-c509-4102-82d7-25a5be83deb5',
            },

            // Week 1 Day 6
            {
              date: zaphodsPlanStartDate.plusDays(5).toString(),
              type: PlanItemType.ExosContent,
              order: 0,
              component: 'Training',
              contentId: '3c0d3539-976a-4741-8f5b-5711d7940907',
            },
            {
              date: zaphodsPlanStartDate.plusDays(5).toString(),
              type: PlanItemType.ExosContent,
              order: 1,
              component: 'Fueling',
              contentId: 'fe02e3a6-97eb-420f-a678-7a69867e2a37',
            },
            {
              date: zaphodsPlanStartDate.plusDays(5).toString(),
              type: PlanItemType.CustomTask,
              order: 2,
              component: 'Sleep',
              title: 'Natural light',
              description:
                'Remember that natural light is an incredibly strong anchor for your circadian rhythm.  So make sure to get 2-10min before 10a, and another 2-10min around sunset as well.',
            },
            {
              date: zaphodsPlanStartDate.plusDays(5).toString(),
              type: PlanItemType.ExosContent,
              order: 3,
              component: 'Daily Movement',
              contentId: '7e9edc96-4e11-4b4d-98d4-1de66e7c9d9b',
            },

            // Week 1 Day 7
            {
              date: zaphodsPlanStartDate.plusDays(6).toString(),
              type: PlanItemType.CustomTask,
              order: 0,
              component: 'Sleep',
              title: 'Natural light',
              description:
                'Remember that natural light is an incredibly strong anchor for your circadian rhythm.  So make sure to get 2-10min before 10a, and another 2-10min around sunset as well.',
            },
            {
              date: zaphodsPlanStartDate.plusDays(6).toString(),
              type: PlanItemType.CustomTask,
              order: 1,
              component: 'Regulation',
              title: 'Breath Work to Stay Calm',
              description:
                'Keep in mind that breath work which extends the exhale compared to the inhale can be an excellent tool to help you keep your cool in heated moments. So as you get ready for next week, take a couple of minutes to reset your nervous system with a few exhale emphasis breath.  3 rounds of a 4-7-8 breath with a 4sec inhale, 7sec hold, and 8sec exhale is a great way to get started!',
            },
          ],
        },
      ],
    },
  ];
};

export const getTaskCoachTrainingMembers = ({
  coachId,
  nutritionistId,
}: {
  coachId: string;
  nutritionistId?: string;
}): DemoUserInput[] => {
  const now = ZonedDateTime.now(ZoneId.UTC);

  return [
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.NewMemberActivationAtRisk,
        due: now,
      },
    },
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.NewMemberActivationAtRisk,
        due: now.minusDays(1),
      },
    },
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.OneWeekPostSessionCheckIn,
        due: now,
      },
    },
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.SessionReminder,
        due: now,
      },
    },
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.SessionReminder,
        due: now,
        isRescheduled: true,
      },
    },
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.SessionReminder,
        due: now.minusDays(1),
        isCancelled: true,
      },
    },
    {
      coachId,
      nutritionistId,
      shouldCreateSessions: true,
      shouldCreatePersonalTrainingSession: true,
      dailyActionConfig: {
        type: CoachTouchPointType.TrackAttendance,
        due: now.minusDays(4),
      },
    },
    {
      coachId,
      nutritionistId,
      shouldCreateSessions: true,
      shouldCreatePersonalTrainingSession: true,
      dailyActionConfig: {
        type: CoachTouchPointType.TrackAttendance,
        sessionType: CoachSessionType.PersonalTraining,
        due: now,
      },
    },
    {
      coachId,
      dailyActionConfig: {
        type: CoachTouchPointType.Custom,
        due: now.plusDays(2),
      },
    },
  ];
};
