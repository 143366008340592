import React from 'react';

import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from 'react-admin';

const AutocompleteClientInput: React.FC<
  Omit<ReferenceInputProps, 'reference' | 'filterToQuery'>
> = ({ source, label, filter, ...props }) => (
  <ReferenceInput
    {...props}
    source={source}
    filter={filter}
    reference="Client"
    filterToQuery={(name: string) => ({
      name,
    })}
  >
    <AutocompleteInput optionText="name" filter="name" label={label} />
  </ReferenceInput>
);

export default AutocompleteClientInput;
