import {
  ChronoField,
  ChronoUnit,
  DateTimeFormatter,
  DateTimeFormatterBuilder,
  Duration,
  Instant,
  LocalDate,
  LocalDateTime,
  Period,
  TemporalAccessor,
  ZoneId,
  ZonedDateTime,
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import pluralize from 'pluralize';

const dayOrdinals: Record<number, string> = {};
for (let i = 1; i <= 31; i += 1) {
  dayOrdinals[i] = i.toString();
}

export const dateTimeFromSeconds = (seconds: number) =>
  ZonedDateTime.ofInstant(Instant.ofEpochSecond(seconds), ZoneId.SYSTEM);

export const getAgeFromBirthday = (
  birthday: string | null | undefined,
): string | null => {
  if (!birthday) {
    return null;
  }
  let bday;
  try {
    bday = LocalDate.parse(birthday);
  } catch {
    return null;
  }
  const today = LocalDate.now();
  const age = Period.between(bday, today);
  if (age.isNegative()) {
    return null;
  }
  const years = age.years();
  return `${years} ${years === 1 ? 'year' : 'years'} old`;
};

const applyDateFormat =
  (fmt: DateTimeFormatter) => (date: string | TemporalAccessor) => {
    const temporal =
      typeof date === 'string' ? ZonedDateTime.parse(date) : date;
    return fmt.format(temporal);
  };

const shortDateFormat = DateTimeFormatter.ofPattern('MM/dd/yyyy').withLocale(
  Locale.US,
);

const shorterDateFormat = DateTimeFormatter.ofPattern('M/dd/yyyy').withLocale(
  Locale.US,
);

export const shorterDate = applyDateFormat(shorterDateFormat);

export const shortDate = applyDateFormat(shortDateFormat);

const monthDayOrdinalYearFormat = DateTimeFormatter.ofPattern(
  'MMM dd, yyyy',
).withLocale(Locale.US);

const monthYearFormat = DateTimeFormatter.ofPattern('MMM yyyy').withLocale(
  Locale.US,
);

export const monthYear = applyDateFormat(monthYearFormat);

export const monthDayOrdinalYear = applyDateFormat(monthDayOrdinalYearFormat);

const fullDateNoYearFormat = new DateTimeFormatterBuilder()
  .appendPattern('eeee, MMMM ')
  .appendText(ChronoField.DAY_OF_MONTH, dayOrdinals)
  .toFormatter()
  .withLocale(Locale.US);

export const fullDateNoYear = applyDateFormat(fullDateNoYearFormat);

const fullDateYearFormat = new DateTimeFormatterBuilder()
  .appendPattern('eeee, MMMM ')
  .appendText(ChronoField.DAY_OF_MONTH, dayOrdinals)
  .appendPattern(' yyyy')
  .toFormatter()
  .withLocale(Locale.US);

export const fullDateYear = applyDateFormat(fullDateYearFormat);

const shortDateNoYearFormat = new DateTimeFormatterBuilder()
  .appendPattern('MMM d')
  .toFormatter()
  .withLocale(Locale.US);

export const shortDateNoYear = applyDateFormat(shortDateNoYearFormat);

const shortDateOfMonthFormat = new DateTimeFormatterBuilder()
  .appendPattern('d')
  .toFormatter()
  .withLocale(Locale.US);

export const shortDateOfMonth = applyDateFormat(shortDateOfMonthFormat);

const shortDateYearFormat = new DateTimeFormatterBuilder()
  .appendPattern('MMM d')
  .appendPattern(', yyyy')
  .toFormatter()
  .withLocale(Locale.US);

export const shortDateYear = applyDateFormat(shortDateYearFormat);

const shortDayMonthDateYearFormat = new DateTimeFormatterBuilder()
  .appendPattern('E, MMM d, yyyy')
  .toFormatter()
  .withLocale(Locale.US);

export const shortDayMonthDateYear = applyDateFormat(
  shortDayMonthDateYearFormat,
);

/**
 * F O R M A T T E R S
 */

export const iso8601DateTime = applyDateFormat(DateTimeFormatter.ISO_INSTANT);
export const iso8601Date = applyDateFormat(DateTimeFormatter.ISO_LOCAL_DATE);

// 7:47PM
export const timeOfDay = DateTimeFormatter.ofPattern('h:mma').withLocale(
  Locale.US,
);
// 7:47 PM
export const timeOfDayWithSpace = DateTimeFormatter.ofPattern(
  'h:mm a',
).withLocale(Locale.US);

export const time = applyDateFormat(timeOfDayWithSpace);

export const dateAndTime = applyDateFormat(
  DateTimeFormatter.ofPattern('MMM d, yyyy, h:mm a').withLocale(Locale.US),
);

// Oct 22, 2001
export const monthDayYear = DateTimeFormatter.ofPattern('MMM d, y').withLocale(
  Locale.US,
);

export const dateOrPast = (zdt?: ZonedDateTime | null) =>
  zdt ?? ZonedDateTime.ofLocal(LocalDateTime.MIN, ZoneId.UTC);

export const dateOrFuture = (zdt?: ZonedDateTime | null) =>
  zdt ?? ZonedDateTime.ofLocal(LocalDateTime.MAX, ZoneId.UTC);

/**
 * T I M E Z O N E   S H E N A N I G A N S
 *
 * ZonedDateTime.now()                  = "2021-05-14T12:23:56.586-04:00[America/New_York]"
 * .withZoneSameInstant(ZoneId.SYSTEM)  = "2021-05-14T12:23:56.586-04:00[SYSTEM]"
 */

export const stringToZonedDateTime = (dateTime: string) =>
  ZonedDateTime.parse(dateTime).withZoneSameInstant(ZoneId.SYSTEM);

export const formatJoinedDate = (createdAt: string) => {
  const createdLocalDate = LocalDateTime.parse(
    createdAt,
    DateTimeFormatter.ISO_ZONED_DATE_TIME,
  );
  if (Duration.between(createdLocalDate, LocalDateTime.now()).toDays() < 1) {
    return 'Joined today';
  }

  return `Joined ${createdLocalDate.format(shortDateFormat)}`;
};

export const formatChatDate = (
  chatDate: LocalDate,
  today: LocalDate,
): string => {
  const days = chatDate.until(today, ChronoUnit.DAYS);
  if (days === 0) {
    return 'Today';
  } else if (days === 1) {
    return 'Yesterday';
  } else if (days > 1 && days < 350) {
    return fullDateNoYear(chatDate);
  } else {
    return fullDateYear(chatDate);
  }
};

export const formatShortChatDate = (
  chatDate: LocalDate,
  today: LocalDate,
): string => {
  const days = chatDate.until(today, ChronoUnit.DAYS);
  if (days === 0) {
    return 'Today';
  } else if (days === 1) {
    return 'Yesterday';
  } else if (days > 1 && days < 350) {
    return shortDateNoYear(chatDate);
  } else {
    return shortDateYear(chatDate);
  }
};

export const pluralizedDayOfWeek = (dayOfWeek: string) =>
  ({
    Sun: 'Sundays',
    Mon: 'Mondays',
    Tue: 'Tuesdays',
    Wed: 'Wednesdays',
    Thu: 'Thursdays',
    Fri: 'Fridays',
    Sat: 'Saturdays',
  })[dayOfWeek] ?? '';

export const timeFromHourMinute = (hourMinute: string) => {
  if (!hourMinute.includes(':')) {
    return '';
  }

  const hour = parseInt(hourMinute.split(':')[0], 10);
  const minute = parseInt(hourMinute.split(':')[1], 10);

  const paddedHour = `00${hour < 13 ? hour : hour - 12}`.slice(-2);
  const paddedMinute = `00${minute}`.slice(-2);
  return `${paddedHour}:${paddedMinute} ${hour < 13 ? 'AM' : 'PM'}`;
};

export const getTimeSince = (
  date?: LocalDate,
  today: LocalDate = LocalDate.now(),
): string => {
  const unknown = 'unknown';
  try {
    if (!date) {
      return unknown;
    }

    const years = date.until(today, ChronoUnit.YEARS);
    const months = date.until(today, ChronoUnit.MONTHS);
    const weeks = date.until(today, ChronoUnit.WEEKS);
    const days = date.until(today, ChronoUnit.DAYS);

    if (years > 0) {
      return `${pluralize('year', years, true)} ago`;
    } else if (months > 0) {
      return `${pluralize('month', months, true)} ago`;
    } else if (weeks > 0) {
      return `${pluralize('week', weeks, true)} ago`;
    } else if (days === 0) {
      return 'Today';
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days > 0) {
      return `${days} days ago`;
    } else if (days === -1) {
      return 'Tomorrow';
    } else if (days < -1) {
      return date.format(monthDayYear);
    } else {
      return unknown;
    }
  } catch {
    return unknown;
  }
};

export const dateInPast = (date: string): boolean => {
  const zonedDate = stringToZonedDateTime(new Date(date).toISOString());
  const now = ZonedDateTime.now();

  return zonedDate.isBefore(now);
};
