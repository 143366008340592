import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { useAuth } from '@teamexos/fit-shared';

const useLogout = () => {
  const client = useApolloClient();
  const { signOut } = useAuth();

  const logout = useCallback(async () => {
    await signOut(false);
    await client.clearStore();
  }, [client, signOut]);

  return logout;
};

export default useLogout;
