import * as React from 'react';

import { useApolloClient } from '@apollo/client';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import Business from '@mui/icons-material/Business';
import Chat from '@mui/icons-material/Chat';
import Email from '@mui/icons-material/Email';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import Person from '@mui/icons-material/Person';
import Place from '@mui/icons-material/Place';
import buildGraphQLProvider from 'ra-data-graphql';
import { Admin, CustomRoutes, Resource } from 'react-admin';
// Upgrading to latest RA causes a funny issue where we need to have ONLY react-router-dom in dependencies but import Route from react-router
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from 'react-router';

import ExosAuthProvider from '../../admin/ExosAuthProvider';
import buildQuery, { introspection } from '../../admin/buildQuery';
import { canWrite as userCanWrite } from '../../admin/permissions';
import { SplitFlags, useFeatureFlags } from '../../hooks/useFeatureFlags';
import useLogout from '../../hooks/useLogout';
import theme from '../../theme';
import { EnvLevel, getEnvLevel } from '../../utils/getEnvLevel';

import {
  ChallengeCreate,
  ChallengeEdit,
  ChallengeShow,
  ChallengesList,
} from './Challenges';
import { ChatRoomCreate, ChatRoomEdit, ChatRoomList } from './ChatRoom';
import { ClientCreate, ClientEdit, ClientList, ClientShow } from './Client';
import { DomainCreate, DomainEdit, DomainList, DomainShow } from './Domain';
import {
  EligibleEmailAddressCreate,
  EligibleEmailAddressEdit,
  EligibleEmailAddressList,
  EligibleEmailAddressShow,
} from './EligibleEmailAddress';
import { GenreCreate, GenreEdit, GenreList, GenreShow } from './Genre';
import { CustomLayout } from './Layout';
import {
  LocationCreate,
  LocationEdit,
  LocationList,
  LocationShow,
} from './Location';
import { StudioCreate, StudioEdit, StudioList, StudioShow } from './Studio';
import { SystemPreferences } from './SystemPreferences';
import { TestData } from './TestData';
import { UserCreate, UserEdit, UserList, UserShow } from './User';

const AdminPage: React.FC = () => {
  const client = useApolloClient();
  const logout = useLogout();
  const { flags } = useFeatureFlags();
  const enableTestDataService = flags[SplitFlags.SPA_TEST_DATA_SERVICE];

  const [authProvider] = React.useState(
    () => new ExosAuthProvider(client, logout),
  );

  const dataProvider = buildGraphQLProvider({
    buildQuery,
    client,
    introspection,
  });

  if (!dataProvider) {
    return <div>Loading</div>;
  }

  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={false}
      layout={CustomLayout}
    >
      {(permissions) => {
        const canWrite = userCanWrite(permissions);
        return (
          <>
            <Resource
              name="Client"
              list={ClientList}
              create={canWrite ? ClientCreate : undefined}
              edit={canWrite ? ClientEdit : undefined}
              show={ClientShow}
              icon={Business}
              recordRepresentation={(record) => `#${record.id}`}
            />
            <Resource
              name="User"
              list={UserList}
              create={canWrite ? UserCreate : undefined}
              edit={canWrite ? UserEdit : undefined}
              show={UserShow}
              icon={Person}
              recordRepresentation={(record) =>
                `${record.firstName} ${record.lastName}`
              }
            />
            <Resource
              name="Studio"
              list={StudioList}
              show={StudioShow}
              edit={canWrite ? StudioEdit : undefined}
              create={canWrite ? StudioCreate : undefined}
              icon={FitnessCenter}
            />
            <Resource
              name="Domain"
              list={DomainList}
              edit={canWrite ? DomainEdit : undefined}
              show={DomainShow}
              create={canWrite ? DomainCreate : undefined}
            />
            <Resource
              name="ChatRoom"
              options={{ label: 'Chat Rooms' }}
              list={ChatRoomList}
              create={canWrite ? ChatRoomCreate : undefined}
              edit={canWrite ? ChatRoomEdit : undefined}
              icon={Chat}
            />
            <Resource
              name="Challenge"
              list={ChallengesList}
              show={ChallengeShow}
              edit={canWrite ? ChallengeEdit : undefined}
              create={canWrite ? ChallengeCreate : undefined}
              icon={EmojiEvents}
            />

            <Resource
              name="CoachLocation"
              list={LocationList}
              show={LocationShow}
              edit={canWrite ? LocationEdit : undefined}
              create={canWrite ? LocationCreate : undefined}
              options={{ label: 'Locations' }}
              icon={Place}
            />
            <Resource
              name="EligibleEmailAddress"
              list={EligibleEmailAddressList}
              show={EligibleEmailAddressShow}
              edit={canWrite ? EligibleEmailAddressEdit : undefined}
              create={canWrite ? EligibleEmailAddressCreate : undefined}
              icon={Email}
            />
            <Resource
              name="Genre"
              list={GenreList}
              show={GenreShow}
              edit={canWrite ? GenreEdit : undefined}
              create={canWrite ? GenreCreate : undefined}
              icon={AutoAwesomeMotionIcon}
              options={{ label: 'Genres' }}
            />
            <CustomRoutes>
              {getEnvLevel() === EnvLevel.DEV && enableTestDataService && (
                <Route path="/testData" element={<TestData />} />
              )}
              <Route
                path="/systemPreferences"
                element={<SystemPreferences />}
              />
            </CustomRoutes>
          </>
        );
      }}
    </Admin>
  );
};

export default AdminPage;
